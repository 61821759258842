import React, { useEffect, useState } from 'react';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { jwtDecode } from 'jwt-decode';
import Home from './components/Home';
import TitleBar from './components/TitleBar';
import Signup from './components/Signup';
import SignIn from './components/Signin';
import Contracts from './components/Contracts';
import Lawyer from './components/Lawyer';
import LawyerRoute from './components/lawyer/LawyerRoute';
import Players from './components/Players';
import AdminRoute from './components/AdminRoute';
import ObservatoryRoute from './components/ObservatoryRoute'
import Constellation from './components/Constellation';
import Settings from './components/Settings';
import './App.css';
import Tokens from './components/settings/Tokens';
import RestrictedAreas from './components/settings/RestrictedAreas';
import AdminHome from './components/AdminHome';
import AdminHomeRoute from './components/AdminHomeRoute';
import PublicContractView from './components/PublicContractView';
import ModeratorOrAdminRoute from './components/ModeratorOrAdminRoute';

const darkTheme = createTheme({
  palette: {
    mode: 'dark',
  },
});

export const backendURL = "https://tmp.enbyte.dev";
export const frontendURL = "https://lr.enbyte.dev";

const isTokenExpired = (token) => {
  if (!token) return true;

  try {
    const decoded = jwtDecode(token);
    const currentTime = Date.now() / 1000; // Convert to seconds
    return decoded.exp < currentTime;
  } catch (error) {
    console.error("Error decoding token:", error);
    return true;
  }
};

const App = () => {
  const [isTokenValid, setIsTokenValid] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const checkTokenValidity = () => {
    const token = localStorage.getItem('token');
    if (token && !isTokenExpired(token)) {
      setIsTokenValid(true);
    } else {
      localStorage.removeItem('token');
      setIsTokenValid(false);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    checkTokenValidity();
  }, []);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="App">
      <BrowserRouter>
        <ThemeProvider theme={darkTheme}>
          <CssBaseline />
          <TitleBar onLogout={checkTokenValidity} onLogin={checkTokenValidity} />
          <Routes>
            <Route path="signup" element={<Signup />} />
            <Route path="/viewContract/:uuid" element={<PublicContractView />} />
            <Route path="signin" element={<SignIn onLogin={checkTokenValidity} />} />
            <Route path="home" element={isTokenValid ? <AdminHomeRoute /> : <SignIn onLogin={checkTokenValidity} />} />
            <Route path="contracts" element={isTokenValid ? <Contracts /> : <Navigate to="/signin" />} />
            <Route path="lawyer" element={<LawyerRoute element={Lawyer} />} />

            {/* Admin Routes */}
            <Route path="players" element={<ModeratorOrAdminRoute element={Players} />} />
            <Route path="constellation" element={<ObservatoryRoute element={Constellation} />} />
            <Route path="settings" element={<AdminRoute element={Settings} />} />
            <Route path="settings/tokens" element={<AdminRoute element={Tokens} />} />
            <Route path="settings/restrictedAreas" element={<AdminRoute element={RestrictedAreas} />} />
            <Route path="adminhome" element={<ModeratorOrAdminRoute element={AdminHome} />} />

            <Route path="*" element={isTokenValid ? <Home /> : <Navigate to="/signin" />} />
          </Routes>
        </ThemeProvider>
      </BrowserRouter>
    </div>
  );
};

export default App;
