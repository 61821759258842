import React, { useState, useEffect } from 'react';
import { Box, Button, Paper, Typography } from '@mui/material';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { getDimensionList } from './apiFunctions';

const buttonTheme = createTheme({
  palette: {
    primary: {
      main: '#1976d2'
    },
    secondary: {
      main: '#dc004e'
    }
  }
});

function DimensionList({ setAlignedDimension }) {
  const [dimensions, setDimensions] = useState([]);
  const [selectedDimension, setSelectedDimension] = useState('');

  useEffect(() => {
    getDimensionList(setDimensions);
  }, []);

  const handleDimensionChange = (newDimension) => {
    setSelectedDimension(newDimension);
    setAlignedDimension(newDimension); // Call the prop function to update the dimension in the parent
  };

  if (dimensions.length === 0) {
    return <Typography variant="h6">Loading...</Typography>;
  }

  const dimensionButtons = dimensions.map((dimension) => (
    <Button 
      key={dimension}
      onClick={() => handleDimensionChange(dimension)} 
      sx={{ 
        backgroundColor: selectedDimension === dimension ? '#11111B' : 'primary', 
        m: 1 
      }}
    >
      {dimension}
    </Button>
  ));

  return (
    <ThemeProvider theme={buttonTheme}>
      <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 2, p: 2 }}>
        <Paper elevation={3} sx={{ p: 2, textAlign: 'center', width: '100%', maxWidth: 600, backgroundColor: '#212121' }}>
          <Box sx={{ display: 'flex', justifyContent: 'center', flexWrap: 'wrap', gap: 1 }}>
            {dimensionButtons}
          </Box>
        </Paper>
      </Box>
    </ThemeProvider>
  );
}

export default DimensionList;
