import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { backendURL } from '../App';
import { Container, Typography, CircularProgress, Alert, Paper, Box, Grid } from '@mui/material';

const UserDetails = () => {
  const [userDetails, setUserDetails] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');

  useEffect(() => {
    const fetchUserDetails = async () => {
      try {
        const token = localStorage.getItem('token');
        if (!token) {
          setError('No auth token found; try refreshing the page.');
          setLoading(false);
          return;
        }

        const response = await axios.get(`${backendURL}/public/userDetails`, {
          headers: {
            Authorization: `Bearer ${token}`
          }
        });
        setUserDetails(response.data);
      } catch (err) {
        console.error("Error fetching user details:", err);
        setError('Error fetching user details');
      } finally {
        setLoading(false);
      }
    };

    fetchUserDetails();
  }, []);

  if (loading) {
    return <CircularProgress />;
  }

  if (error) {
    return <Alert severity="error">{error}</Alert>;
  }

  const renderGrid = (accessLevel) => {
    const colors = {
      '0': '#017BC7', // Light blue
      '1': '#264179'  // Dark blue
    };

    const cells = accessLevel.split('').map((bit, index) => (
      <Grid item xs={6} key={index}>
        <Box
          sx={{
            width: '100%',
            height: 100,
            backgroundColor: colors[bit],
            border: '2px solid #000', // Black border
          }}
        />
      </Grid>
    ));

    return (
      <Grid container spacing={1} sx={{ width: 210, margin: 'auto' }}>
        {cells}
      </Grid>
    );
  };

  return (
    <Container>
      <Paper elevation={3} sx={{ p: 3, mt: 5, textAlign: 'center' }}>
        <Typography variant="h2" gutterBottom>MyLR</Typography><hr></hr>
        <Typography variant="h4" gutterBottom>Welcome, {userDetails.username}</Typography>
      </Paper>
      <Paper elevation={3} sx={{ p: 3, mt: 5, textAlign: 'center' }}>
        {userDetails && (
          <Box>
            <Typography variant="h4" sx={{ mb: 1 }}>Access Level</Typography><hr></hr>
            <Typography variant="h5"><b>{userDetails.accessLevel}</b></Typography>
            <Box sx={{ mb: 3 }}>
              {renderGrid(userDetails.accessLevel)}
            </Box>
            <Typography variant="body1"><i>This means you can access Lilarreich areas marked with the above pattern of light/dark concrete.</i></Typography>
          </Box>
        )}
      </Paper>
      <Paper elevation={3} sx={{ p: 3, mt: 5, textAlign: 'center' }}>
        {userDetails && (
          <Box>
            <Typography variant="h4">Additional Information</Typography><hr></hr>
            <Typography variant="body1" sx={{ mb: 1 }}><b>Billable Points: {userDetails.billablePoints}</b></Typography>
            <Typography variant="body1"><i>Billable points are accumulated through your usage of Lilarreich services.</i></Typography>
            <Typography variant="body1"><i>Occasionally, a bill will be generated and delivered to you.</i></Typography><br></br>
            <Typography variant="body1"><b>Status: {userDetails.status.join(', ')}</b></Typography>
            <Typography variant="body1"><i>Your status can be a list of different keywords that may afford you extra access to Lilarreich services.</i></Typography>
          </Box>
        )}
      </Paper>
    </Container>
  );
};

export default UserDetails;
