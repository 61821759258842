import React, { useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';
import axios from 'axios';
import { backendURL } from '../App';

const AdminHomeRoute = ({ element: Component, ...rest }) => {
  const [isAdmin, setIsAdmin] = useState(false);
  const [isAuthorized, setIsAuthorized] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchUserStatus = async () => {
      const token = localStorage.getItem('token');
      if (token) {
        try {
          const response = await axios.get(`${backendURL}/public/userDetails`, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });
          const userDetails = response.data;
          const userIsAdmin = userDetails.status.includes('Admin');
          const userIsModerator = userDetails.status.includes('Moderator');

          setIsAdmin(userIsAdmin);
          setIsAuthorized(userIsAdmin || userIsModerator);
        } catch (error) {
          console.error('Error fetching user status:', error);
        }
      }
      setIsLoading(false);
    };
    fetchUserStatus();
  }, []);

  if (isLoading) {
    return <div>Loading...</div>; // Show a loading indicator while checking the status
  }

  if (!isAuthorized) {
    return <Navigate to="/" />;
  }

  return isAuthorized ? <Navigate to="/adminHome" /> : <Navigate to="/" />;
};

export default AdminHomeRoute;
