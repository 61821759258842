import axios from "axios";
import { backendURL } from "../../App";

export function getPlayerList(setPlayers) {
  const token = localStorage.getItem('token');
  if (!token) {
    console.error("Token is missing");
    setPlayers([]);
    return;
  }

  axios.get(`${backendURL}/api/frontend/players`, {
    headers: { Authorization: `Bearer ${token}` }
  })
    .then(res => {
      setPlayers(res.data.players);
    })
    .catch(err => {
      console.error(err);
      setPlayers([]);
    });
}

export function getPlayerLocation(setCurrentLocation, player) {
  const token = localStorage.getItem('token');
  if (!token) {
    console.error("Token is missing");
    setCurrentLocation(null);
    return;
  }

  axios.get(`${backendURL}/api/frontend/players/align`, {
    headers: { Authorization: `Bearer ${token}` },
    params: { player }
  })
    .then(res => {
      setCurrentLocation(res.data);
    })
    .catch(err => {
      console.error(err);
      setCurrentLocation(null);
    });
}

export function getPlayerHistory(setHistory, player, limit, dimension) {
  const token = localStorage.getItem('token');
  if (!token) {
    console.error("Token is missing");
    setHistory(null);
    return;
  }

  axios.get(`${backendURL}/api/frontend/players/history`, {
    headers: { Authorization: `Bearer ${token}` },
    params: { player, limit, dimension }
  })
    .then(res => {
      setHistory(res.data.records);
    })
    .catch(err => {
      console.error(err);
      setHistory(null);
    });
}

export function getDimensionList(setDimensions) {
  const token = localStorage.getItem('token');
  if (!token) {
    console.error("Token is missing");
    setDimensions([]);
    return;
  }

  axios.get(`${backendURL}/api/frontend/dimensions`, {
    headers: { Authorization: `Bearer ${token}` }
  })
    .then(res => {
      setDimensions(res.data.dimensions);
    })
    .catch(err => {
      console.error(err);
      setDimensions([]);
    });
}

export function getOnlinePlayers(setOnlinePlayers) {
  const token = localStorage.getItem('token');
  if (!token) {
    console.error("Token is missing");
    setOnlinePlayers([]);
    return;
  }

  axios.get(`${backendURL}/api/frontend/players/radar`, {
    headers: { Authorization: `Bearer ${token}` }
  })
    .then(res => {
      setOnlinePlayers(res.data.players);
    })
    .catch(err => {
      console.error("Error fetching online players:", err);
      setOnlinePlayers([]);
    });
}
