import React, {useEffect} from 'react';

function SelectedPlayerOverview(props) {
    useEffect(() => { }, []);

  return (
    <div className="App">
          <h1>{props.aligned}</h1>
          <PlayerLocation aligned={props.aligned} location={props.location} />
    </div>
  );
}

function PlayerLocation(props) {
  if (props.location?.xPos === undefined) { return <h1>Loading information...</h1> }
  if (props.location === null) { return <h1>ERROR</h1> }

  return (
    <div className="App">
      <b>Last entry: </b> {(new Intl.DateTimeFormat('en-US', { dateStyle: 'full', timeStyle: 'long', timeZone: 'America/Phoenix' }).format(props.location.timestamp))}<br></br>
      <b>X </b>{props.location.xPos} <b>Y </b>{props.location.yPos} <b>Z </b>{props.location.zPos} <b>Dim </b>{props.location.dimension}
    </div>
  );
}

export default SelectedPlayerOverview;