import { backendURL } from "../../App";
import axios from "axios";

export async function getSentContracts(token) {
    try {
      const response = await fetch(`${backendURL}/public/sentContracts`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (!response.ok) {
        throw new Error('Failed to fetch sent contracts');
      }
      return await response.json();
    } catch (error) {
      console.error('Error fetching sent contracts:', error);
      throw error;
    }
  }

  export function getPlayerList(token, setPlayers) {
    axios.get(`${backendURL}/public/listPlayers`, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
    .then(res => {
      setPlayers(res.data.players);
    })
    .catch(err => {
      console.log(err);
      setPlayers(null);
    });
  }