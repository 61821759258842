import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { ListItem, ListItemText, ListItemSecondaryAction, IconButton, TextField, Switch, FormControlLabel, Box, InputAdornment, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import CheckIcon from '@mui/icons-material/Check';
import { backendURL } from '../../App';

const SettingItem = ({ setting = {}, onEditMetadata }) => {
  const [editValue, setEditValue] = useState(setting.value || '');
  const [hover, setHover] = useState(false);
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [pendingValue, setPendingValue] = useState(setting.value || '');

  useEffect(() => {
    setEditValue(setting.value || '');
  }, [setting.value]);

  const updateSetting = (key, value, pseudoType, favorite) => {
    if (pseudoType === 'number' && isNaN(value)) {
      value = '0';
    } else if (pseudoType === 'boolean') {
      value = value === 'true' ? 'true' : 'false';
    }

    const token = localStorage.getItem('token');
    if (!token) {
      console.error("Token is missing");
      return;
    }

    axios.get(`${backendURL}/api/frontend/settings/set`, {
      headers: { Authorization: `Bearer ${token}` },
      params: { key, value, pseudoType, favorite: favorite ? 'true' : 'false' }
    })
      .then(res => {
        console.log('Setting updated successfully');
        setEditValue(value); // Update the local state with the new value
      })
      .catch(err => {
        console.log(err);
      });
  };

  const handleToggleBoolean = () => {
    const newValue = editValue === 'true' ? 'false' : 'true';
    if (setting.setting && setting.setting.startsWith('SENSITIVE-')) {
      setPendingValue(newValue);
      setConfirmOpen(true);
    } else {
      updateSetting(setting.setting, newValue, setting.pseudoType, setting.favorite);
    }
  };

  const handleTextChange = (e) => {
    setEditValue(e.target.value);
  };

  const handleNumberChange = (e) => {
    const value = e.target.value;
    setEditValue(value);
  };

  const handleSubmit = () => {
    if (setting.setting && setting.setting.startsWith('SENSITIVE-')) {
      setPendingValue(editValue);
      setConfirmOpen(true);
    } else {
      if (setting.pseudoType === 'number' && isNaN(editValue)) {
        setEditValue('0');
        updateSetting(setting.setting, '0', setting.pseudoType, setting.favorite);
      } else {
        updateSetting(setting.setting, editValue, setting.pseudoType, setting.favorite);
      }
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      handleSubmit();
    }
  };

  const handleConfirmClose = () => {
    setConfirmOpen(false);
  };

  const handleConfirmChange = () => {
    setConfirmOpen(false);
    if (setting.pseudoType === 'number' && isNaN(pendingValue)) {
      updateSetting(setting.setting, '0', setting.pseudoType, setting.favorite);
    } else {
      updateSetting(setting.setting, pendingValue, setting.pseudoType, setting.favorite);
    }
  };

  return (
    <>
      <ListItem
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
      >
        <ListItemText
          primary={setting.setting || ''}
          secondary={
            setting.pseudoType === 'boolean' ? (
              <FormControlLabel
                control={
                  <Switch
                    checked={editValue === 'true'}
                    onChange={handleToggleBoolean}
                    color="primary"
                  />
                }
                label={editValue === 'true' ? 'True' : 'False'}
              />
            ) : setting.pseudoType === 'number' ? (
              <Box display="flex" alignItems="center">
                <TextField
                  type="number"
                  value={editValue}
                  onChange={handleNumberChange}
                  onKeyPress={handleKeyPress}
                  fullWidth
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <IconButton onClick={handleSubmit} color="primary">
                          <CheckIcon />
                        </IconButton>
                      </InputAdornment>
                    )
                  }}
                />
              </Box>
            ) : (
              <Box display="flex" alignItems="center">
                <TextField
                  value={editValue}
                  onChange={handleTextChange}
                  onKeyPress={handleKeyPress}
                  fullWidth
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <IconButton onClick={handleSubmit} color="primary">
                          <CheckIcon />
                        </IconButton>
                      </InputAdornment>
                    )
                  }}
                />
              </Box>
            )
          }
        />
        {hover && onEditMetadata && (
          <ListItemSecondaryAction>
            <IconButton edge="end" aria-label="edit" onClick={() => onEditMetadata(setting)}>
              <EditIcon />
            </IconButton>
          </ListItemSecondaryAction>
        )}
      </ListItem>
      <Dialog
        open={confirmOpen}
        onClose={handleConfirmClose}
        aria-labelledby="confirm-change-dialog-title"
        aria-describedby="confirm-change-dialog-description"
      >
        <DialogTitle id="confirm-change-dialog-title">{"Confirm Change"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="confirm-change-dialog-description">
            Are you sure you want to change this sensitive setting?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleConfirmClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleConfirmChange} color="secondary">
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default SettingItem;
