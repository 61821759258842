import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { backendURL } from '../../App';
import { Container, Typography, TextField, Button, List, ListItem, ListItemText, ListItemSecondaryAction, IconButton, Divider, Box, Paper, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Link } from 'react-router-dom';

function RestrictedAreas(props) {
  const [restrictedAreas, setRestrictedAreas] = useState([]);
  const [newArea, setNewArea] = useState({
    name: '',
    x1: '',
    y1: '',
    z1: '',
    x2: '',
    y2: '',
    z2: '',
    dimension: '',
    accessLevelRequired: ''
  });
  const [status, setStatus] = useState('init');
  const [open, setOpen] = useState(false);
  const [deleteArea, setDeleteArea] = useState('');

  useEffect(() => {
    listRestrictedAreas();
  }, []);

  function listRestrictedAreas() {
    const token = localStorage.getItem('token');
    if (!token) {
      console.error("Token is missing");
      setStatus('error');
      return;
    }

    axios.get(`${backendURL}/api/frontend/settings/restrictedAreas/list`, {
      headers: { Authorization: `Bearer ${token}` }
    })
      .then(res => {
        setRestrictedAreas(res.data);
        setStatus('success');
      })
      .catch(err => {
        console.log(err);
        setStatus('error');
      });
  }

  function addRestrictedArea(area) {
    const token = localStorage.getItem('token');
    if (!token) {
      console.error("Token is missing");
      setStatus('error');
      return;
    }

    axios.get(`${backendURL}/api/frontend/settings/restrictedAreas/add`, {
      headers: { Authorization: `Bearer ${token}` },
      params: {
        ...area
      }
    })
      .then(res => {
        listRestrictedAreas();
      })
      .catch(err => {
        console.log(err);
        setStatus('error');
      });
  }

  function handleClickOpen(name) {
    setDeleteArea(name);
    setOpen(true);
  }

  function handleClose() {
    setOpen(false);
    setDeleteArea('');
  }

  function handleDelete() {
    removeRestrictedArea(deleteArea);
    handleClose();
  }

  function removeRestrictedArea(name) {
    const token = localStorage.getItem('token');
    if (!token) {
      console.error("Token is missing");
      setStatus('error');
      return;
    }

    axios.get(`${backendURL}/api/frontend/settings/restrictedAreas/remove`, {
      headers: { Authorization: `Bearer ${token}` },
      params: { name }
    })
      .then(res => {
        listRestrictedAreas();
      })
      .catch(err => {
        console.log(err);
        setStatus('error');
      });
  }

  return (
    <Container maxWidth="md">
      <Box my={4}>
        <Typography variant="h4" component="h1" gutterBottom>
          Restricted Areas
        </Typography>
        <Box display="flex" justifyContent="flex-start" my={2}>
          <Button
            variant="contained"
            color="primary"
            startIcon={<ArrowBackIcon />}
            component={Link}
            to="/Settings"
          >
            Back to Settings
          </Button>
        </Box>
        {status === 'init' && <Typography variant="h6">Loading...</Typography>}
        {status === 'error' && <Typography variant="h6" color="error">Error loading restricted areas.</Typography>}
        {status === 'success' && (
          <Box>
            <Paper elevation={3} sx={{ p: 2 }}>
              <List>
                {restrictedAreas.map((area, index) => (
                  <React.Fragment key={index}>
                    <ListItem>
                      <ListItemText
                        primary={area.name}
                        secondary={`Coordinates: (${area.x1}, ${area.y1}, ${area.z1}) to (${area.x2}, ${area.y2}, ${area.z2}), Dimension: ${area.dimension}, Access Level: ${area.accessLevelRequired}`}
                      />
                      <ListItemSecondaryAction>
                        <IconButton edge="end" aria-label="delete" onClick={() => handleClickOpen(area.name)}>
                          <DeleteIcon />
                        </IconButton>
                      </ListItemSecondaryAction>
                    </ListItem>
                    {index < restrictedAreas.length - 1 && <Divider />}
                  </React.Fragment>
                ))}
              </List>
              <Box my={4}>
                <Typography variant="h6">Add Restricted Area</Typography>
                <TextField label="Name" value={newArea.name} onChange={e => setNewArea({ ...newArea, name: e.target.value })} fullWidth margin="normal" />
                <TextField label="X1" value={newArea.x1} onChange={e => setNewArea({ ...newArea, x1: e.target.value })} fullWidth margin="normal" />
                <TextField label="Y1" value={newArea.y1} onChange={e => setNewArea({ ...newArea, y1: e.target.value })} fullWidth margin="normal" />
                <TextField label="Z1" value={newArea.z1} onChange={e => setNewArea({ ...newArea, z1: e.target.value })} fullWidth margin="normal" />
                <TextField label="X2" value={newArea.x2} onChange={e => setNewArea({ ...newArea, x2: e.target.value })} fullWidth margin="normal" />
                <TextField label="Y2" value={newArea.y2} onChange={e => setNewArea({ ...newArea, y2: e.target.value })} fullWidth margin="normal" />
                <TextField label="Z2" value={newArea.z2} onChange={e => setNewArea({ ...newArea, z2: e.target.value })} fullWidth margin="normal" />
                <TextField label="Dimension" value={newArea.dimension} onChange={e => setNewArea({ ...newArea, dimension: e.target.value })} fullWidth margin="normal" />
                <TextField label="Access Level Required" value={newArea.accessLevelRequired} onChange={e => setNewArea({ ...newArea, accessLevelRequired: e.target.value })} fullWidth margin="normal" />
                <Button variant="contained" color="primary" onClick={() => addRestrictedArea(newArea)}>
                  Add
                </Button>
              </Box>
            </Paper>
          </Box>
        )}
      </Box>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Confirm Delete"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to delete this restricted area? This action cannot be undone.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleDelete} color="secondary" autoFocus>
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
}

export default RestrictedAreas;
