import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Container, Button, Typography, Box, Paper, List, ListItem, ListItemText, Avatar, Dialog, DialogContent, DialogTitle, DialogActions, Collapse, IconButton, Tooltip } from '@mui/material';
import { backendURL } from '../../App';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import HourglassEmptyIcon from '@mui/icons-material/HourglassEmpty';
import CancelIcon from '@mui/icons-material/Cancel';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import WarningIcon from '@mui/icons-material/Warning';
import { frontendURL } from '../../App';

const ContractList = ({ token, refresh }) => {
  const [sentContracts, setSentContracts] = useState([]);
  const [oldContractsOpen, setOldContractsOpen] = useState(false);
  const [open, setOpen] = useState(false);
  const [selectedContract, setSelectedContract] = useState(null);
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [contractToExpire, setContractToExpire] = useState({ contractUUID: null, contractId: null });

  useEffect(() => {
    const fetchSentContracts = async () => {
      try {
        const response = await axios.get(`${backendURL}/public/sentContracts`, {
          headers: {
            Authorization: `Bearer ${token}`
          }
        });
        const sortedContracts = response.data.sort((a, b) => b.sentTimestamp - a.sentTimestamp);
        setSentContracts(sortedContracts);
      } catch (err) {
        console.error('Error fetching sent contracts:', err);
      }
    };

    fetchSentContracts();
  }, [token, refresh]);

  const handleViewContract = (contractUUID) => {
    setSelectedContract(contractUUID);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedContract(null);
  };

  const handleCopyLink = () => {
    const contractLink = `${frontendURL}/viewcontract/${selectedContract}`;
    navigator.clipboard.writeText(contractLink)
      .then(() => {
        console.log("Link copied to clipboard");
      })
      .catch(() => {
        console.error("Failed to copy link");
      });
  };

  const handleExpireContract = async () => {
    if (contractToExpire.contractUUID && contractToExpire.contractId) {
      try {
        await axios.post(`${backendURL}/public/expireContract`, { 
          contractUUID: contractToExpire.contractUUID, 
          contractId: contractToExpire.contractId 
        }, {
          headers: {
            Authorization: `Bearer ${token}`
          }
        });
        setSentContracts((prevContracts) => prevContracts.map((contract) =>
          contract.contractUUID === contractToExpire.contractUUID ? { ...contract, signed: 'expired' } : contract
        ));
        setConfirmOpen(false);
      } catch (err) {
        console.error("Error expiring contract:", err);
      }
    }
  };

  const openConfirmDialog = (contractUUID, contractId) => {
    setContractToExpire({ contractUUID, contractId });
    setConfirmOpen(true);
  };

  const closeConfirmDialog = () => {
    setConfirmOpen(false);
    setContractToExpire({ contractUUID: null, contractId: null });
  };

  const formatDate = (timestamp) => {
    return new Date(timestamp).toLocaleDateString() + ' ' + new Date(timestamp).toLocaleTimeString();
  };

  const oneWeekAgo = Date.now() - 7 * 24 * 60 * 60 * 1000;
  const recentContracts = sentContracts.filter(contract => contract.sentTimestamp >= oneWeekAgo);
  const oldContracts = sentContracts.filter(contract => contract.sentTimestamp < oneWeekAgo);

  const getStatusIcon = (status) => {
    const statusText = `Status: ${status === 'true' ? 'Signed' : status === 'rejected' ? 'Rejected' : status === 'expired' ? 'Expired' : 'Pending'}`;
    if (status === 'true') return <Tooltip title={statusText}><CheckCircleIcon style={{ color: 'green', marginRight: '10px' }} /></Tooltip>;
    if (status === 'rejected') return <Tooltip title={statusText}><CancelIcon style={{ color: 'red', marginRight: '10px' }} /></Tooltip>;
    if (status === 'expired') return <Tooltip title={statusText}><CancelIcon style={{ color: 'gray', marginRight: '10px' }} /></Tooltip>;
    return <Tooltip title={statusText}><HourglassEmptyIcon style={{ color: 'orange', marginRight: '10px' }} /></Tooltip>;
  };

  return (
    <Container maxWidth="md">
      <Paper elevation={3} sx={{ p: 3, mt: 5 }}>
        <Typography variant="h4" gutterBottom>Sent Contracts</Typography>
        {recentContracts.length === 0 && oldContracts.length === 0 ? (
          <Typography variant="body1">No contracts sent.</Typography>
        ) : (
          <>
            <List>
              {recentContracts.map((contract) => (
                <ListItem key={contract.contractUUID} sx={{ alignItems: 'center' }}>
                  {getStatusIcon(contract.signed)}
                  <Avatar
                    alt={contract.username}
                    src={`${backendURL}/public/${contract.username}.png`}
                    sx={{ mr: 2 }}
                  />
                  <ListItemText
                    primary={`Contract: ${contract.contractDetails || 'No details provided'}`}
                    secondary={
                      <>
                        <Typography component="span" variant="body2" color="textSecondary">
                          Sent: {formatDate(contract.sentTimestamp)}
                        </Typography>
                        {contract.signedTimestamp && (
                          <Typography component="span" variant="body2" color="textSecondary" display="block">
                            Signed: {formatDate(contract.signedTimestamp)}
                          </Typography>
                        )}
                      </>
                    }
                  />
                  {!contract.signed && (
                    <Button
                      variant="outlined"
                      color="error"
                      onClick={() => openConfirmDialog(contract.contractUUID, contract.contractId)}
                      sx={{ ml: 1 }}
                    >
                      Expire
                    </Button>
                  )}
                  <Button
                    variant="outlined"
                    color="primary"
                    onClick={() => handleViewContract(contract.contractUUID)}
                    sx={{ ml: 1 }}
                  >
                    View
                  </Button>
                </ListItem>
              ))}
            </List>
            {oldContracts.length > 0 && (
              <>
                <Button onClick={() => setOldContractsOpen(!oldContractsOpen)} fullWidth>
                  {oldContractsOpen ? 'Hide Older Contracts' : 'Show Older Contracts'} {oldContractsOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                </Button>
                <Collapse in={oldContractsOpen}>
                  <List>
                    {oldContracts.map((contract) => (
                      <ListItem key={contract.contractUUID} sx={{ alignItems: 'center' }}>
                        {getStatusIcon(contract.signed)}
                        <Avatar
                          alt={contract.username}
                          src={`${backendURL}/public/${contract.username}.png`}
                          sx={{ mr: 2 }}
                        />
                        <ListItemText
                          primary={`Contract: ${contract.contractDetails || 'No details provided'}`}
                          secondary={
                            <>
                              <Typography component="span" variant="body2" color="textSecondary">
                                Sent: {formatDate(contract.sentTimestamp)}
                              </Typography>
                              {contract.signedTimestamp && (
                                <Typography component="span" variant="body2" color="textSecondary" display="block">
                                  Signed: {formatDate(contract.signedTimestamp)}
                                </Typography>
                              )}
                            </>
                          }
                        />
                        {!contract.signed && (
                          <Button
                            variant="outlined"
                            color="error"
                            onClick={() => openConfirmDialog(contract.contractUUID, contract.contractId)}
                            sx={{ ml: 1 }}
                          >
                            Expire
                          </Button>
                        )}
                        <Button
                          variant="outlined"
                          color="primary"
                          onClick={() => handleViewContract(contract.contractUUID)}
                          sx={{ ml: 1 }}
                        >
                          View
                        </Button>
                      </ListItem>
                    ))}
                  </List>
                </Collapse>
              </>
            )}
          </>
        )}
      </Paper>

      <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
        <DialogTitle>
          View Contract
          <IconButton
            color="primary"
            onClick={handleCopyLink}
            sx={{ float: 'right' }}
          >
            <FileCopyIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          {selectedContract && (
            <iframe
              src={`${backendURL}/public/viewContract/${selectedContract}`}
              width="100%"
              height="600px"
              title="Contract"
            />
          )}
        </DialogContent>
      </Dialog>

      <Dialog
        open={confirmOpen}
        onClose={closeConfirmDialog}
      >
        <DialogTitle>Confirm Expiration</DialogTitle>
        <DialogContent>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <WarningIcon color="warning" sx={{ mr: 1 }} />
            <Typography variant="body1">Are you sure you want to expire this contract?</Typography>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleExpireContract} color="primary">Expire</Button>
          <Button onClick={closeConfirmDialog} color="secondary">Cancel</Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};

export default ContractList;
