import React, { useState, useEffect, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { Container, TextField, Button, Typography, Box, Alert } from '@mui/material';
import { backendURL } from '../App';

const Signup = () => {
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [tokenValid, setTokenValid] = useState(false);
  const isMounted = useRef(true);
  const navigate = useNavigate();

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const token = searchParams.get('token');

  useEffect(() => {
    isMounted.current = true;
    const verifyToken = async () => {
      try {
        const response = await axios.get(`${backendURL}/public/verifyToken`, {
          params: { token }
        });
        if (response.status === 200 && response.data.username) {
          setTokenValid(true);
        } else {
          setTokenValid(false);
        }
      } catch (error) {
        setTokenValid(false);
      }
    };

    if (token) {
      verifyToken();
    } else {
      setTokenValid(false);
    }

    return () => {
      isMounted.current = false;
    };
  }, [token]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    setSuccess('');

    if (password !== confirmPassword) {
      setError('Passwords do not match');
      return;
    }

    try {
      const response = await axios.post(`${backendURL}/public/signup`, {
        token,
        password,
      });
      if (response.status === 200) {
        if (isMounted.current) {
          setSuccess('Password set successfully! Redirecting...');
          setTimeout(() => {
            navigate('/signin');
          }, 1000); // Wait 2 seconds before redirecting
        }
      } else {
        if (isMounted.current) {
          setError('Failed to set password');
        }
      }
    } catch (error) {
      if (isMounted.current) {
        setError('Failed to set password');
      }
    }
  };

  if (!tokenValid) {
    return (
      <Container maxWidth="sm">
        <Box mt={5}>
          <Typography variant="h4" component="h1" gutterBottom>
            Invalid or expired link
          </Typography>
        </Box>
      </Container>
    );
  }

  return (
    <Container maxWidth="sm">
      <Box mt={5}>
        <Typography variant="h4" component="h1" gutterBottom>
          Set Your Password for MyLR
        </Typography>
        <form onSubmit={handleSubmit}>
          <TextField
            label="New Password"
            type="password"
            fullWidth
            margin="normal"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />
          <TextField
            label="Confirm Password"
            type="password"
            fullWidth
            margin="normal"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
            required
          />
          {error && <Alert severity="error">{error}</Alert>}
          {success && <Alert severity="success">{success}</Alert>}
          <Button type="submit" variant="contained" color="primary" fullWidth sx={{ mt: 2 }}>
            Set Password
          </Button>
        </form>
      </Box>
    </Container>
  );
};

export default Signup;
