import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { backendURL } from '../App';
import { Container, Grid, Box, Typography, Paper, List } from '@mui/material';
import gear from '../assets/gear.png';
import NotificationsIcon from '@mui/icons-material/Notifications';
import InventoryIcon from '@mui/icons-material/Inventory';
import Alerts from './home/Alerts';
import SettingItem from './home/SettingItem';
import Energy from './home/Energy';

function AdminHome(props) {
  const { isAdmin } = props; // Destructure isAdmin from props
  const [settings, setSettings] = useState([]);
  const [alerts, setAlerts] = useState([]);

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      listSettings(token);
      getAlerts(token);
    } else {
      console.error("No token provided");
    }
  }, []);

  function listSettings(token) {
    axios.get(`${backendURL}/api/frontend/settings/list`, {
      headers: { Authorization: `Bearer ${token}` }
    })
      .then(res => {
        setSettings(res.data.settings);
      })
      .catch(err => {
        console.error(err);
        setSettings([]);
      });
  }

  function getAlerts(token) {
    axios.get(`${backendURL}/api/frontend/alerts/list`, {
      headers: { Authorization: `Bearer ${token}` }
    })
      .then(res => {
        setAlerts(res.data);
      })
      .catch(err => {
        console.error(err);
        setAlerts([]);
      });
  }

  const favoritedSettings = settings.filter(setting => setting.favorite);

  return (
    <Container maxWidth="lg">
      <Box sx={{ my: 4, textAlign: 'center' }}>
        <img src={gear} className="App-logo" alt="Gear" />
        <Typography variant="h4" component="h1" gutterBottom>
          Welcome to the IWM
        </Typography>
        <Typography variant="h6" component="p">
          Navigate using the menu above.
        </Typography>
      </Box>

      <Grid container spacing={4}>
        <Grid item xs={12} md={6}>
          <Energy token={localStorage.getItem('token')} />
        </Grid>

        <Grid item xs={12} md={6}>
          <Paper elevation={3} sx={{ p: 2 }}>
            <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
              <InventoryIcon sx={{ mr: 1 }} />
              <Typography variant="h6" component="div">
                Favorite Settings
              </Typography>
            </Box>
            {favoritedSettings.length > 0 ? (
              <List>
                {favoritedSettings.map((setting, index) => (
                  <SettingItem
                    key={index}
                    setting={setting}
                    token={localStorage.getItem('token')}
                  />
                ))}
              </List>
            ) : (
              <Typography variant="h6">No Favorite Settings Available</Typography>
            )}
          </Paper>
        </Grid>

        <Grid item xs={12}>
          <Paper elevation={3} sx={{ p: 2 }}>
            <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
              <NotificationsIcon sx={{ mr: 1 }} />
              <Typography variant="h6" component="div">
                Active Alerts
              </Typography>
            </Box>
            <Alerts token={localStorage.getItem('token')} isAdmin={isAdmin} />
          </Paper>
        </Grid>
      </Grid>
    </Container>
  );
}

export default AdminHome;
