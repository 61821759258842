import React, { useState, useEffect } from 'react';
import SelectedPlayerOverview from './constellation/SelectedPlayerOverview';
import PlayerList from './constellation/PlayerList';
import { getPlayerHistory, getPlayerLocation, getOnlinePlayers } from './constellation/apiFunctions';
import BubbleChart from './constellation/BubbleChart';
import ActiveRadar from './constellation/ActiveRadar';
import DimensionList from './constellation/DimensionList';
import InstancesWithinRadius from './constellation/InstancesWithinRadius';
import { Box, Typography, Paper, Grid, Accordion, AccordionSummary, AccordionDetails, Button } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

function Constellation(props) {
  const [aligned, setAligned] = useState('');
  const [currentLocation, setCurrentLocation] = useState([]);
  const [history, setHistory] = useState([]);
  const [dimension, setDimension] = useState('minecraft:overworld');
  const [targetCoordinates, setTargetCoordinates] = useState({ x: 0, y: 0, z: 0 });
  const [radius, setRadius] = useState(0);
  const [withinRadius, setWithinRadius] = useState([]);
  const [viewMode, setViewMode] = useState('bubble'); // New state to track view mode
  const [onlinePlayers, setOnlinePlayers] = useState([]); // State to store radar data

  useEffect(() => {
    if (aligned) {
      const interval = setInterval(() => getInformation(aligned), 5000);
      return () => clearInterval(interval);
    }
  }, [aligned, dimension]);

  useEffect(() => {
    if (aligned) {
      getInformation(aligned);
    }
  }, [dimension, aligned]);

  useEffect(() => {
    if (history.length > 0) {
      calculateWithinRadius();
    }
  }, [history, targetCoordinates, radius]);

  // Fetch online players for ActiveRadar view
  useEffect(() => {
    if (viewMode === 'radar') {
      const fetchOnlinePlayers = () => getOnlinePlayers(setOnlinePlayers);

      // Fetch every 5 seconds while in radar view
      fetchOnlinePlayers();
      const interval = setInterval(fetchOnlinePlayers, 5000);
      return () => clearInterval(interval);
    }
  }, [viewMode]);

  function setAlignedPlayer(player) {
    setAligned(player);
    getInformation(player);
  }

  function setAlignedDimension(newDimension) {
    setDimension(newDimension);
  }

  function getInformation(player) {
    if (!player) return;
    getPlayerLocation(setCurrentLocation, player);
    getPlayerHistory(setHistory, player, 1000, dimension);
  }

  function handleTargetCoordinateChange(event) {
    const { name, value } = event.target;
    setTargetCoordinates((prev) => ({
      ...prev,
      [name]: value ? Number(value) : '',
    }));
  }

  function handleRadiusChange(event) {
    setRadius(Number(event.target.value));
  }

  function calculateWithinRadius() {
    const filtered = history.filter((log) => {
      const dx = log.xPos - targetCoordinates.x;
      const dy = log.yPos - targetCoordinates.y;
      const dz = log.zPos - targetCoordinates.z;
      const distance = Math.sqrt(dx ** 2 + dy ** 2 + dz ** 2);
      return distance <= radius;
    });
    setWithinRadius(filtered);
  }

  // Toggle function to switch between BubbleChart and ActiveRadar
  function toggleViewMode() {
    setViewMode((prevMode) => (prevMode === 'bubble' ? 'radar' : 'bubble'));
  }

  return (
    <Box sx={{ p: 2, display: 'flex', flexDirection: 'column', gap: 2 }}>
      {/* Toggle Button at the Top */}
      <Button variant="contained" color="primary" onClick={toggleViewMode} sx={{ alignSelf: 'start' }}>
        Switch to {viewMode === 'bubble' ? 'ActiveRadar' : 'BubbleChart'}
      </Button>

      {/* Conditionally render PlayerList and DimensionList only for BubbleChart */}
      {viewMode === 'bubble' && (
        <Grid container spacing={2}>
          <Grid item xs={12} md={7}>
            <Paper elevation={3} sx={{ p: 2, backgroundColor: '#212121' }}>
              <PlayerList handleSelection={setAlignedPlayer} token={props.token} />
            </Paper>
          </Grid>
          <Grid item xs={12} md={5}>
            <Paper elevation={3} sx={{ p: 2, backgroundColor: '#212121' }}>
              <DimensionList setAlignedDimension={setAlignedDimension} token={props.token} />
            </Paper>
          </Grid>
        </Grid>
      )}

      {/* Conditionally render details only when BubbleChart is active */}
      {aligned && viewMode === 'bubble' && (
        <>
          <Paper elevation={3} sx={{ p: 2, backgroundColor: '#212121' }}>
            <SelectedPlayerOverview aligned={aligned} location={currentLocation} />
          </Paper>
          <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography>Additional Searchable Parameters</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <Typography>Search Radius Around Target</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <InstancesWithinRadius
                    targetCoordinates={targetCoordinates}
                    radius={radius}
                    handleTargetCoordinateChange={handleTargetCoordinateChange}
                    handleRadiusChange={handleRadiusChange}
                    withinRadius={withinRadius}
                  />
                </AccordionDetails>
              </Accordion>
            </AccordionDetails>
          </Accordion>
        </>
      )}

      {/* Conditionally render BubbleChart or ActiveRadar based on viewMode */}
      {viewMode === 'bubble' ? (
        <BubbleChart location={currentLocation} history={history} dimension={dimension} />
      ) : (
        <ActiveRadar players={onlinePlayers} />
      )}
    </Box>
  );
}

export default Constellation;
