import * as React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import ThemeProvider from '@mui/material/styles/ThemeProvider';
import createTheme from '@mui/material/styles/createTheme';
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import HomeIcon from '@mui/icons-material/Home';
import InventoryIcon from '@mui/icons-material/Inventory';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import GavelIcon from '@mui/icons-material/Gavel';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import SatelliteAltIcon from '@mui/icons-material/SatelliteAlt';
import BookIcon from '@mui/icons-material/Book';
import MenuIcon from '@mui/icons-material/Menu';
import { Box, IconButton, Drawer, List, ListItem, ListItemIcon, ListItemText } from '@mui/material';
import axios from 'axios';
import { backendURL } from '../App';

const navTheme = createTheme({
  palette: {
    primary: {
      main: '#800080',
    },
    text: {
      secondary: '#800080',
    },
  },
});

function TitleBar({ onLogout, onLogin }) {
  const [value, setValue] = React.useState('Home');
  const [isAuthorized, setIsAuthorized] = React.useState(false);
  const [isAdmin, setIsAdmin] = React.useState(false); // Add isAdmin state
  const [isLawyer, setIsLawyer] = React.useState(false);
  const [isObservatory, setIsObservatory] = React.useState(false);
  const [drawerOpen, setDrawerOpen] = React.useState(false);
  const navigate = useNavigate();

  const fetchUserStatus = async () => {
    const token = localStorage.getItem('token');
    if (token) {
      try {
        const response = await axios.get(`${backendURL}/public/userDetails`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        const userDetails = response.data;
        const isAdmin = userDetails.status.includes('Admin');
        const isModerator = userDetails.status.includes('Moderator');

        setIsAdmin(isAdmin); // Set isAdmin state
        setIsAuthorized(isAdmin || isModerator);
        setIsLawyer(userDetails.status.includes('Lawyer'));
        setIsObservatory(userDetails.status.includes('Observatory'));
      } catch (error) {
        console.error('Error fetching user status:', error);
      }
    }
  };

  React.useEffect(() => {
    fetchUserStatus();
  }, [onLogin]); // Re-fetch status on login

  const handleChange = (event, newValue) => {
    setValue(newValue);
    fetchUserStatus(); // Refresh status every time a button is clicked
  };

  const handleLogout = () => {
    localStorage.removeItem('token');
    onLogout(); // Call the prop function to handle logout
    navigate('/signin');
  };

  const toggleDrawer = (open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setDrawerOpen(open);
  };

  const token = localStorage.getItem('token');

  if (!token) {
    return null;
  }

  const menuItems = [
    { label: 'Home', icon: <HomeIcon />, link: '/home', show: true },
    { label: 'Contracts', icon: <BookIcon />, link: '/contracts', show: true },
    { label: 'Lawyer', icon: <GavelIcon />, link: '/lawyer', show: isLawyer },
    { label: 'Players', icon: <PeopleAltIcon />, link: '/players', show: isAuthorized },
    { label: 'Constellation', icon: <SatelliteAltIcon />, link: '/constellation', show: isObservatory },
    { label: 'Settings', icon: <InventoryIcon />, link: '/settings', show: isAdmin }, // Settings only visible to Admin
  ];

  return (
    <ThemeProvider theme={navTheme}>
      <Box sx={{ display: 'flex', alignItems: 'center', width: '100%', backgroundColor: '#303030' }}>
        <IconButton
          edge="start"
          color="primary"
          aria-label="menu"
          onClick={toggleDrawer(true)}
          sx={{ ml: 2, display: { xs: 'block', sm: 'none' } }}
        >
          <MenuIcon />
        </IconButton>
        <Drawer
          anchor="left"
          open={drawerOpen}
          onClose={toggleDrawer(false)}
          PaperProps={{
            sx: {
              backgroundColor: '#303030', // Set the background color of the drawer
            },
          }}
        >
          <Box sx={{ width: 250 }} role="presentation" onClick={toggleDrawer(false)} onKeyDown={toggleDrawer(false)}>
            <List>
              {menuItems.filter(item => item.show).map((item) => (
                <ListItem
                  button
                  key={item.label}
                  component={Link}
                  to={item.link}
                  onClick={() => {
                    setValue(item.label);
                    fetchUserStatus(); // Refresh status on menu item click
                  }}
                >
                  <ListItemIcon sx={{ color: value === item.label ? '#800080' : '#000000' }}>{item.icon}</ListItemIcon>
                  <ListItemText primary={item.label} sx={{ color: '#800080' }} />
                </ListItem>
              ))}
              <ListItem button onClick={handleLogout}>
                <ListItemIcon sx={{ color: '#800080' }}><ExitToAppIcon /></ListItemIcon>
                <ListItemText primary="Logout" sx={{ color: '#800080' }} />
              </ListItem>
            </List>
          </Box>
        </Drawer>
        <BottomNavigation
          sx={{ flexGrow: 1, backgroundColor: 'transparent', display: { xs: 'none', sm: 'flex' } }}
          value={value}
          onChange={handleChange}
        >
          {menuItems.filter(item => item.show).map((item) => (
            <BottomNavigationAction
              key={item.label}
              component={Link}
              to={item.link}
              label={item.label}
              value={item.label}
              icon={React.cloneElement(item.icon, { sx: { color: value === item.label ? '#800080' : '#000000' } })}
            />
          ))}
        </BottomNavigation>
        <IconButton
          onClick={handleLogout}
          sx={{ color: '#800080', ml: 'auto', display: { xs: 'none', sm: 'block' } }}
        >
          <ExitToAppIcon />
        </IconButton>
      </Box>
    </ThemeProvider>
  );
}

export default TitleBar;
