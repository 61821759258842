import React, { useState } from 'react';
import axios from 'axios';
import { Container, Button, Typography, Box, Alert, Paper, TextField } from '@mui/material';
import PlayerList from './PlayerList';
import { backendURL } from '../../App';

const SendContract = ({ token, refreshContracts }) => {
  const [recipientUsername, setRecipientUsername] = useState('');
  const [contractFile, setContractFile] = useState(null);
  const [contractDetails, setContractDetails] = useState('');
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file && file.type !== 'application/pdf') {
      setError('Only PDF files are allowed');
      setContractFile(null);
      return;
    }
    setContractFile(file);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    setSuccess('');

    if (!contractFile) {
      setError("Please upload a contract file.");
      return;
    }

    const formData = new FormData();
    formData.append('contractFile', contractFile);
    formData.append('username', recipientUsername);
    formData.append('contractDetails', contractDetails);

    try {
      const response = await axios.post(`${backendURL}/public/sendContract`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${token}`
        }
      });

      if (response.status === 200) {
        setSuccess("Contract sent successfully");
        setRecipientUsername('');
        setContractFile(null);
        setContractDetails('');
        refreshContracts(); // Call the function to refresh contracts in the parent component
      } else {
        setError("Error sending contract");
      }
    } catch (err) {
      console.error("Error sending contract:", err);
      setError("Error sending contract");
    }
  };

  return (
    <Container maxWidth="md">
      <Paper elevation={3} sx={{ p: 3, mt: 5 }}>
        <Box mt={3} mb={3} textAlign="center">
          <Typography variant="h4" gutterBottom>Send Contract</Typography>
        </Box>
        <form onSubmit={handleSubmit}>
          <Box mb={3}>
            <PlayerList token={token} handleSelection={setRecipientUsername} />
          </Box>
          {recipientUsername && (
            <Typography variant="h5" gutterBottom align="center">
              Selected Player: <b>{recipientUsername}</b>
            </Typography>
          )}
          <TextField
            label="Contract Details"
            fullWidth
            margin="normal"
            value={contractDetails}
            onChange={(e) => setContractDetails(e.target.value)}
            required
          />
          <Box mb={3} textAlign="center">
            <Button
              variant="contained"
              component="label"
              color="primary"
            >
              Upload Contract
              <input
                type="file"
                accept="application/pdf"
                hidden
                onChange={handleFileChange}
                required
              />
            </Button>
          </Box>
          {error && <Alert severity="error">{error}</Alert>}
          {success && <Alert severity="success">{success}</Alert>}
          <Button type="submit" variant="contained" color="primary" fullWidth sx={{ mt: 2 }}>
            Send Contract
          </Button>
        </form>
      </Paper>
    </Container>
  );
};

export default SendContract;
