import React, { useEffect, useState } from 'react';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';

function AccessConfig(props) {
  const [input, setInput] = useState('');
  const [open, setOpen] = useState(false);

  useEffect(() => {
    setInput('');
  }, [props.level]);

  if (props.player === '') { return <h1>Please select a user above.</h1>; }
  if (props.player === null) { return <h1>ERROR</h1>; }

  const handleInputChange = (e) => {
    const value = e.target.value;
    if (/^[0-1]{0,4}$/.test(value)) {
      setInput(value);
    }
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    if (input.length === 4 && /^[0-1]{4}$/.test(input)) {
      props.handleSettings(input);
    } else {
      setOpen(true);
    }
  }

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  return (
    <div className="App">
      <br /><b>Manage Access Level</b><br />
      <form onSubmit={handleSubmit}>
        <TextField
          id="button"
          label={`${props.level}`}
          value={input}
          color="secondary"
          variant="outlined"
          onChange={handleInputChange}
        />
        <div><br />
          <Button type="submit" variant="outlined">Edit</Button>
        </div>
      </form>
      <Snackbar
        open={open}
        autoHideDuration={3000}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <Alert onClose={handleClose} severity="error" sx={{ width: '100%' }}>
          Invalid: Input must be exactly 4 digits of 0 or 1.
        </Alert>
      </Snackbar>
    </div>
  );
}

export default AccessConfig;
