import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Container, Typography, List, ListItem, ListItemText, Button, Alert, Paper, Box, Avatar, IconButton, Dialog, DialogContent, DialogTitle, DialogActions, Tooltip, TextField } from '@mui/material';
import { backendURL } from '../App';
import CopyIcon from '@mui/icons-material/ContentCopy';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import HourglassEmptyIcon from '@mui/icons-material/HourglassEmpty';
import CancelIcon from '@mui/icons-material/Cancel';
import WarningIcon from '@mui/icons-material/Warning';
import FileUploadIcon from '@mui/icons-material/CloudUpload';
import {jwtDecode} from 'jwt-decode';
import { frontendURL } from '../App';

const MyContracts = () => {
  const [contracts, setContracts] = useState([]);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [open, setOpen] = useState(false);
  const [selectedContract, setSelectedContract] = useState(null);
  const [confirmationOpen, setConfirmationOpen] = useState(false);
  const [confirmationAction, setConfirmationAction] = useState('');
  const [contractToConfirm, setContractToConfirm] = useState(null);
  const [uploadDialogOpen, setUploadDialogOpen] = useState(false);
  const [contractFile, setContractFile] = useState(null);
  const [contractDetails, setContractDetails] = useState('');

  useEffect(() => {
    fetchContracts();
  }, []);

  const fetchContracts = async () => {
    try {
      const response = await axios.get(`${backendURL}/public/contracts`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`
        }
      });
      setContracts(response.data);
    } catch (err) {
      console.error("Error fetching contracts:", err);
      setError("Error fetching contracts; try refreshing the page.");
    }
  };

  const handleSignContract = async (contractUUID) => {
    try {
      await axios.post(`${backendURL}/public/signContract`, { contractUUID }, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`
        }
      });
      setSuccess(`Contract signed successfully`);
      setError('');
      setConfirmationOpen(false);
      setContracts((prevContracts) => prevContracts.map((contract) =>
        contract.contractUUID === contractUUID ? { ...contract, signed: 'true', signedTimestamp: Date.now() } : contract
      ));
    } catch (err) {
      console.error("Error signing contract:", err);
      setError("Error signing contract");
      setSuccess('');
    }
  };

  const handleRejectContract = async (contractUUID) => {
    try {
      await axios.post(`${backendURL}/public/rejectContract`, { contractUUID }, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`
        }
      });
      setSuccess(`Contract rejected successfully`);
      setError('');
      setConfirmationOpen(false);
      setContracts((prevContracts) => prevContracts.map((contract) =>
        contract.contractUUID === contractUUID ? { ...contract, signed: 'rejected' } : contract
      ));
    } catch (err) {
      console.error("Error rejecting contract:", err);
      setError("Error rejecting contract");
      setSuccess('');
    }
  };

  const handleViewContract = (contractUUID) => {
    setSelectedContract(contractUUID);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedContract(null);
  };

  const handleCopyURL = () => {
    navigator.clipboard.writeText(`${frontendURL}/viewcontract/${selectedContract}`);
    setSuccess('Contract URL copied to clipboard.');
  };

  const handleConfirmAction = (action, contractUUID) => {
    setConfirmationAction(action);
    setContractToConfirm(contractUUID);
    setConfirmationOpen(true);
  };

  const handleCancelConfirmation = () => {
    setConfirmationOpen(false);
    setContractToConfirm(null);
  };

  const handleConfirm = () => {
    if (confirmationAction === 'sign') {
      handleSignContract(contractToConfirm);
    } else if (confirmationAction === 'reject') {
      handleRejectContract(contractToConfirm);
    }
  };

  const formatDate = (timestamp) => {
    return timestamp ? new Date(timestamp).toLocaleString() : null;
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file && file.type !== 'application/pdf') {
      setError('Only PDF files are allowed');
      setContractFile(null);
      return;
    }
    setContractFile(file);
  };

  const handleUpload = async (e) => {
    e.preventDefault();
    setError('');
    setSuccess('');

    if (!contractFile) {
      setError("Please upload a contract file.");
      return;
    }

    const token = localStorage.getItem('token');
    const decodedToken = jwtDecode(token);
    const username = decodedToken.username;

    const formData = new FormData();
    formData.append('contractFile', contractFile);
    formData.append('username', username);
    formData.append('contractDetails', contractDetails);

    try {
      const response = await axios.post(`${backendURL}/public/sendContract`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${token}`
        }
      });

      if (response.status === 200) {
        setSuccess("Contract sent successfully");
        setContractFile(null);
        setContractDetails('');
        fetchContracts(); // Refresh contracts list
      } else {
        setError("Error sending contract");
      }
    } catch (err) {
      console.error("Error sending contract:", err);
      setError("Error sending contract");
    }

    setUploadDialogOpen(false); // Close the dialog after upload
  };

  const pendingContracts = contracts.filter(contract => !contract.signed || contract.signed === 'pending');
  const signedContracts = contracts.filter(contract => contract.signed === 'true');
  const rejectedContracts = contracts.filter(contract => contract.signed === 'rejected');
  const expiredContracts = contracts.filter(contract => contract.signed === 'expired');

  const getStatusIcon = (status) => {
    const statusText = `Status: ${status === 'true' ? 'Signed' : status === 'rejected' ? 'Rejected' : status === 'expired' ? 'Expired' : 'Pending'}`;
    if (status === 'true') return <Tooltip title={statusText}><CheckCircleIcon style={{ color: 'green', marginRight: '10px' }} /></Tooltip>;
    if (status === 'rejected') return <Tooltip title={statusText}><CancelIcon style={{ color: 'red', marginRight: '10px' }} /></Tooltip>;
    if (status === 'expired') return <Tooltip title={statusText}><CancelIcon style={{ color: 'gray', marginRight: '10px' }} /></Tooltip>;
    return <Tooltip title={statusText}><HourglassEmptyIcon style={{ color: 'orange', marginRight: '10px' }} /></Tooltip>;
  };

  return (
    <Container>
      <br></br>
      <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
        <Typography variant="h4">My Contracts</Typography>
        <Button
          variant="contained"
          color="primary"
          startIcon={<FileUploadIcon />}
          onClick={() => setUploadDialogOpen(true)}
        >
          Send Contract to Self
        </Button>
      </Box>
      {error && <Alert severity="error">{error}</Alert>}
      {success && <Alert severity="success">{success}</Alert>}

      <Paper elevation={3} sx={{ p: 2, mb: 2 }}>
        <Typography variant="h6">Pending Contracts</Typography>
        <List>
          {pendingContracts.map((contract) => (
            <ListItem key={contract.contractUUID}>
              <Avatar
                alt={contract.senderUsername}
                src={`${backendURL}/public/${contract.senderUsername}.png`}
                sx={{ mr: 2 }}
              />
              <ListItemText
                primary={`Contract: ${contract.contractDetails || 'No details provided'}`}
                secondary={
                  <>
                    <Typography variant="body2" color="textSecondary">
                      Sender: {contract.senderUsername}
                    </Typography>
                    <Typography variant="body2" color="textSecondary">
                      Sent: {formatDate(contract.sentTimestamp)}
                    </Typography>
                  </>
                }
              />
              <Button
                variant="contained"
                color="primary"
                onClick={() => handleConfirmAction('sign', contract.contractUUID)}
              >
                E-Sign
              </Button>
              <Button
                variant="outlined"
                color="secondary"
                onClick={() => handleConfirmAction('reject', contract.contractUUID)}
                sx={{ ml: 1 }}
              >
                Reject
              </Button>
              <Button
                variant="outlined"
                color="secondary"
                onClick={() => handleViewContract(contract.contractUUID)}
                sx={{ ml: 1 }}
              >
                View
              </Button>
            </ListItem>
          ))}
        </List>
      </Paper>

      <Paper elevation={3} sx={{ p: 2, mb: 2 }}>
        <Typography variant="h6">Signed Contracts</Typography>
        <List>
          {signedContracts.map((contract) => (
            <ListItem key={contract.contractUUID}>
              <Avatar
                alt={contract.senderUsername}
                src={`${backendURL}/public/${contract.senderUsername}.png`}
                sx={{ mr: 2 }}
              />
              <ListItemText
                primary={`Contract: ${contract.contractDetails || 'No details provided'}`}
                secondary={
                  <>
                    <Typography variant="body2" color="textSecondary">
                      Sender: {contract.senderUsername}
                    </Typography>
                    <Typography variant="body2" color="textSecondary">
                      Sent: {formatDate(contract.sentTimestamp)}
                    </Typography>
                    {contract.signedTimestamp && (
                      <Typography variant="body2" color="textSecondary">
                        Signed: {formatDate(contract.signedTimestamp)}
                      </Typography>
                    )}
                  </>
                }
              />
              <Button
                variant="outlined"
                color="secondary"
                onClick={() => handleViewContract(contract.contractUUID)}
              >
                View
              </Button>
            </ListItem>
          ))}
        </List>
      </Paper>

      <Paper elevation={3} sx={{ p: 2, mb: 2 }}>
        <Typography variant="h6">Rejected Contracts</Typography>
        <List>
          {rejectedContracts.map((contract) => (
            <ListItem key={contract.contractUUID}>
              <Avatar
                alt={contract.senderUsername}
                src={`${backendURL}/public/${contract.senderUsername}.png`}
                sx={{ mr: 2 }}
              />
              <ListItemText
                primary={`Contract: ${contract.contractDetails || 'No details provided'}`}
                secondary={
                  <>
                    <Typography variant="body2" color="textSecondary">
                      Sender: {contract.senderUsername}
                    </Typography>
                    <Typography variant="body2" color="textSecondary">
                      Sent: {formatDate(contract.sentTimestamp)}
                    </Typography>
                  </>
                }
              />
              <Button
                variant="outlined"
                color="secondary"
                onClick={() => handleViewContract(contract.contractUUID)}
              >
                View
              </Button>
            </ListItem>
          ))}
        </List>
      </Paper>

      <Paper elevation={3} sx={{ p: 2, mb: 2 }}>
        <Typography variant="h6">Expired Contracts</Typography>
        <List>
          {expiredContracts.map((contract) => (
            <ListItem key={contract.contractUUID}>
              <Avatar
                alt={contract.senderUsername}
                src={`${backendURL}/public/${contract.senderUsername}.png`}
                sx={{ mr: 2 }}
              />
              <ListItemText
                primary={`Contract: ${contract.contractDetails || 'No details provided'}`}
                secondary={
                  <>
                    <Typography variant="body2" color="textSecondary">
                      Sender: {contract.senderUsername}
                    </Typography>
                    <Typography variant="body2" color="textSecondary">
                      Sent: {formatDate(contract.sentTimestamp)}
                    </Typography>
                  </>
                }
              />
              <Button
                variant="outlined"
                color="secondary"
                onClick={() => handleViewContract(contract.contractUUID)}
              >
                View
              </Button>
            </ListItem>
          ))}
        </List>
      </Paper>

      <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
        <DialogTitle>
          View Contract
          <IconButton
            edge="end"
            aria-label="copy"
            onClick={handleCopyURL}
            sx={{ ml: 2 }}
          >
            <CopyIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          {selectedContract && (
            <iframe
              src={`${backendURL}/public/viewContract/${selectedContract}`}
              width="100%"
              height="600px"
              title="Contract"
            />
          )}
        </DialogContent>
      </Dialog>

      <Dialog open={confirmationOpen} onClose={handleCancelConfirmation}>
        <DialogTitle>Confirm Action</DialogTitle>
        <DialogContent>
          <Typography variant="body1">
            {confirmationAction === 'sign' ? 'Are you sure you want to sign this contract?' : 'Are you sure you want to reject this contract?'}
          </Typography>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 2 }}>
            <Button variant="contained" color="primary" onClick={handleConfirm}>Yes</Button>
            <Button variant="outlined" color="secondary" onClick={handleCancelConfirmation}>No</Button>
          </Box>
        </DialogContent>
      </Dialog>

      <Dialog open={uploadDialogOpen} onClose={() => setUploadDialogOpen(false)} maxWidth="md" fullWidth>
        <DialogTitle>Send Contract to Self</DialogTitle>
        <DialogContent>
          <Typography>This is generally used to upload requested documents with unilateral execution, such as an affidavit or declaration.</Typography>
          <Typography>This officially enters them into the database so that they can be verified and shared.</Typography><br></br>
          <Typography><b>After uploading the document, please remember to sign it so that it's official.</b></Typography>
          <TextField
            label="Contract Details (e.x.: Affidavit of Witness for Party 1 Vs. Party 2)"
            fullWidth
            margin="normal"
            value={contractDetails}
            onChange={(e) => setContractDetails(e.target.value)}
            required
          />
          <Button
            variant="contained"
            component="label"
            color="primary"
            fullWidth
          >
            Upload Contract
            <input
              type="file"
              accept="application/pdf"
              hidden
              onChange={handleFileChange}
              required
            />
          </Button>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setUploadDialogOpen(false)} color="primary">
            Cancel
          </Button>
          <Button onClick={handleUpload} color="primary">
            Upload
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};

export default MyContracts;
