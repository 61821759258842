import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { backendURL } from '../../App';
import { Box, Typography, Paper, TableContainer, Table, TableHead, TableBody, TableRow, TableCell, IconButton } from '@mui/material';
import BoltIcon from '@mui/icons-material/Bolt'; // Import BoltIcon

function Energy(props) {
  const [percent, setPercent] = useState("init");
  const [powerData, setPowerData] = useState([]);
  const [changes, setChanges] = useState({});

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      getCurrentPower(token);
      getPowerHistory(token);
    } else {
      console.error("No token provided");
    }
  }, []);

  function getCurrentPower(token) {
    axios.get(`${backendURL}/api/frontend/energy`, {headers: { Authorization: `Bearer ${token}` }})
      .then(res => {
        setPercent(res.data.status);
      })
      .catch(err => {
        console.error(err);
        setPercent(null);
      });
  }

  function getPowerHistory(token) {
    axios.get(`${backendURL}/api/frontend/energy/history`, {headers: { Authorization: `Bearer ${token}` }})
      .then(res => {
        setPowerData(res.data.history);
        calculateChanges(res.data.history);
      })
      .catch(err => {
        console.error(err);
        setPowerData([]);
      });
  }

  function calculateChanges(data) {
    const changes = [5, 15, 50, 100, 250].reduce((acc, count) => {
      if (data.length >= count) {
        const recentData = data.slice(-count);
        const firstEntry = parseFloat(recentData[0].percentage);
        const lastEntry = parseFloat(recentData[recentData.length - 1].percentage);
        const change = (((lastEntry - firstEntry) / firstEntry) * 100).toFixed(2);
        acc[count] = change;
      } else {
        acc[count] = 'N/A';
      }
      return acc;
    }, {});
    setChanges(changes);
  }

  return (
    <Paper elevation={3} sx={{ p: 2 }}>
      <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
        <BoltIcon sx={{ mr: 1, fontSize: '40px' }} /> {/* Updated to match other icons */}
        <Typography variant="h6" component="div" sx={{ ml: 2 }}>
          Current Power: <b>{percent === "init" ? "..." : percent === null ? "ERROR" : `${percent}%`}</b>
        </Typography>
      </Box>
      <Box className="Changes-container" sx={{ textAlign: 'center', mt: 2 }}>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Entries (# of Most Recent)</TableCell>
                <TableCell align="right">Change (%)</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {Object.entries(changes).map(([count, change]) => (
                <TableRow key={count}>
                  <TableCell component="th" scope="row">{count}</TableCell>
                  <TableCell align="right" sx={{ color: change >= 0 ? 'green' : 'red' }}>
                    {change >= 0 ? `+${change}` : change}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </Paper>
  );
}

export default Energy;
