import React, { useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';
import axios from 'axios';
import { backendURL } from '../App';

const ObservatoryRoute = ({ element: Component, ...rest }) => {
  const [isObservatory, setIsObservatory] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchUserStatus = async () => {
      const token = localStorage.getItem('token');
      if (token) {
        try {
          const response = await axios.get(`${backendURL}/public/userDetails`, {
            headers: {
              Authorization: `Bearer ${token}`
            }
          });
          const userDetails = response.data;
          setIsObservatory(userDetails.status.includes("Observatory"));
        } catch (error) {
          console.error('Error fetching user status:', error);
        }
      }
      setIsLoading(false);
    };
    fetchUserStatus();
  }, []);

  if (isLoading) {
    return <div>Loading...</div>; // Show a loading indicator while checking the status
  }

  return isObservatory ? <Component {...rest} /> : <Navigate to="/" />;
};

export default ObservatoryRoute;
