import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { backendURL } from '../App';
import { Container, Typography, TextField, Button, List, Box, Paper, FormControl, MenuItem, Select, Checkbox, FormControlLabel, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Divider } from '@mui/material';
import KeyIcon from '@mui/icons-material/Key';
import ReportIcon from '@mui/icons-material/Report';
import { Link } from 'react-router-dom';
import SettingItem from './settings/SettingItem';
import RestrictedAreas from './settings/RestrictedAreas'
import Tokens from './settings/Tokens';

function Settings(props) {
  const [settings, setSettings] = useState([]);
  const [newKey, setNewKey] = useState('');
  const [newValue, setNewValue] = useState('');
  const [newPseudoType, setNewPseudoType] = useState('string');
  const [newFavorite, setNewFavorite] = useState(false);
  const [status, setStatus] = useState('init');
  const [open, setOpen] = useState(false);
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [editSetting, setEditSetting] = useState(null);

  useEffect(() => {
    listSettings();
  }, []);

  function listSettings() {
    const token = localStorage.getItem('token');
    if (!token) {
      console.error("Token is missing");
      setStatus('error');
      return;
    }

    axios.get(`${backendURL}/api/frontend/settings/list`, {
      headers: { Authorization: `Bearer ${token}` }
    })
      .then(res => {
        setSettings(res.data.settings);
        setStatus('success');
      })
      .catch(err => {
        console.log(err);
        setStatus('error');
      });
  }

  function addSetting(key, value, pseudoType, favorite) {
    const token = localStorage.getItem('token');
    if (!token) {
      console.error("Token is missing");
      setStatus('error');
      return;
    }

    axios.get(`${backendURL}/api/frontend/settings/add?key=${key}&value=${value}&pseudoType=${pseudoType}&favorite=${favorite}`, {
      headers: { Authorization: `Bearer ${token}` }
    })
      .then(res => {
        listSettings();
      })
      .catch(err => {
        console.log(err);
        setStatus('error');
      });
  }

  function updateSetting(key, value, pseudoType, favorite) {
    if (pseudoType === 'number' && isNaN(value)) {
      value = '0';
    } else if (pseudoType === 'boolean') {
      value = value === 'true' ? 'true' : 'false';
    }

    const token = localStorage.getItem('token');
    if (!token) {
      console.error("Token is missing");
      setStatus('error');
      return;
    }

    axios.get(`${backendURL}/api/frontend/settings/set?key=${key}&value=${value}&pseudoType=${pseudoType}&favorite=${favorite}`, {
      headers: { Authorization: `Bearer ${token}` }
    })
      .then(res => {
        listSettings();
      })
      .catch(err => {
        console.log(err);
        setStatus('error');
      });
  }

  function deleteSetting(key) {
    const token = localStorage.getItem('token');
    if (!token) {
      console.error("Token is missing");
      setStatus('error');
      return;
    }

    axios.get(`${backendURL}/api/frontend/settings/remove?key=${key}`, {
      headers: { Authorization: `Bearer ${token}` }
    })
      .then(res => {
        listSettings();
        setConfirmOpen(false);
        setOpen(false);
        setEditSetting(null);
      })
      .catch(err => {
        console.log(err);
        setStatus('error');
      });
  }

  function handleEditMetadata(setting) {
    setEditSetting(setting);
    setOpen(true);
  }

  function handleMetadataChange(field, value) {
    if (field === 'pseudoType' && value === 'number' && isNaN(editSetting.value)) {
      setEditSetting(prev => ({ ...prev, value: '0', [field]: value }));
    } else if (field === 'pseudoType' && value === 'boolean') {
      const newValue = editSetting.value === 'true' ? 'true' : 'false';
      setEditSetting(prev => ({ ...prev, value: newValue, [field]: value }));
    } else {
      setEditSetting(prev => ({ ...prev, [field]: value }));
    }
  }

  function handleSave() {
    if (editSetting) {
      updateSetting(editSetting.setting, editSetting.value, editSetting.pseudoType, editSetting.favorite);
      setOpen(false);
    }
  }

  function handleDeleteConfirm() {
    setConfirmOpen(true);
  }

  function handleConfirmClose() {
    setConfirmOpen(false);
  }

  function handleDelete() {
    if (editSetting) {
      deleteSetting(editSetting.setting);
    }
  }

  return (
    <Container maxWidth="md">
      <Box my={4}>
        <Typography variant="h4" component="h1" gutterBottom>
          Settings
        </Typography>
        <Box display="flex" justifyContent="flex-start" my={2} gap={2}>
          <Button
            variant="contained"
            color="primary"
            startIcon={<KeyIcon />}
            component={Link}
            to="/settings/tokens"
          >
            Go to Tokens
          </Button>
          <Button
            variant="contained"
            color="primary"
            startIcon={<ReportIcon />}
            component={Link}
            to="/settings/restrictedAreas"
          >
            Go to Restricted Areas
          </Button>
        </Box>
        {status === 'init' && <Typography variant="h6">Loading...</Typography>}
        {status === 'error' && <Typography variant="h6" color="error">Error loading settings.</Typography>}
        {status === 'success' && (
          <Box>
            <Paper elevation={3} sx={{ p: 2 }}>
              <List>
                {settings.map((setting, index) => (
                  <React.Fragment key={index}>
                    <SettingItem
                      setting={setting}
                      token={props.token}
                      onEditMetadata={handleEditMetadata}
                    />
                    {index < settings.length - 1 && <Divider />}
                  </React.Fragment>
                ))}
              </List>
            </Paper>
            <Box my={4}>
              <Typography variant="h6">Add Setting</Typography>
              <TextField label="Key" value={newKey} onChange={e => setNewKey(e.target.value)} fullWidth margin="normal" />
              <TextField label="Value" value={newValue} onChange={e => setNewValue(e.target.value)} fullWidth margin="normal" />
              <FormControl fullWidth margin="normal">
                <Select
                  value={newPseudoType}
                  onChange={e => setNewPseudoType(e.target.value)}
                  displayEmpty
                >
                  <MenuItem value="string">String</MenuItem>
                  <MenuItem value="number">Number</MenuItem>
                  <MenuItem value="boolean">Boolean</MenuItem>
                </Select>
              </FormControl>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={newFavorite}
                    onChange={e => setNewFavorite(e.target.checked)}
                    color="primary"
                  />
                }
                label="Favorite"
              />
              <Button variant="contained" color="primary" onClick={() => addSetting(newKey, newValue, newPseudoType, newFavorite)}>
                Add
              </Button>
            </Box>
          </Box>
        )}
      </Box>
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="edit-metadata-dialog-title"
        aria-describedby="edit-metadata-dialog-description"
      >
        <DialogTitle id="edit-metadata-dialog-title">{"Edit Metadata"}</DialogTitle>
        <DialogContent>
          <TextField
            label="Value"
            value={editSetting?.value || ''}
            onChange={(e) => handleMetadataChange('value', e.target.value)}
            fullWidth
            margin="normal"
          />
          <FormControl fullWidth margin="normal">
            <Select
              value={editSetting?.pseudoType || 'string'}
              onChange={(e) => handleMetadataChange('pseudoType', e.target.value)}
            >
              <MenuItem value="string">String</MenuItem>
              <MenuItem value="number">Number</MenuItem>
              <MenuItem value="boolean">Boolean</MenuItem>
            </Select>
          </FormControl>
          <FormControlLabel
            control={
              <Checkbox
                checked={editSetting?.favorite || false}
                onChange={(e) => handleMetadataChange('favorite', e.target.checked)}
                color="primary"
              />
            }
            label="Favorite"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen(false)} color="primary">
            Cancel
          </Button>
          <Button onClick={handleSave} color="primary">
            Save
          </Button>
          <Button onClick={handleDeleteConfirm} color="secondary">
            Delete
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={confirmOpen}
        onClose={handleConfirmClose}
        aria-labelledby="confirm-delete-dialog-title"
        aria-describedby="confirm-delete-dialog-description"
      >
        <DialogTitle id="confirm-delete-dialog-title">{"Confirm Delete"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="confirm-delete-dialog-description">
            Are you sure you want to delete this setting? This action cannot be undone.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleConfirmClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleDelete} color="secondary">
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
}

export default Settings;
