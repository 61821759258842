import React, { useState } from 'react';
import {
  Chart as ChartJS,
  LinearScale,
  PointElement,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bubble } from 'react-chartjs-2';

ChartJS.register(LinearScale, PointElement, Tooltip, Legend);

const options = {
  aspectRatio: 1,
  scales: {
    x: {
      min: -2500,
      max: 2500,
    },
    y: {
      beginAtZero: false,
      min: -2500,
      max: 2500,
      reverse: true,
    },
  },
};

function BubbleChart(props) {
  const [filterLevel, setFilterLevel] = useState(0); // State to control the slider value

  if (props.history.length === 0) {
    return <h1>...</h1>;
  }

  // Filter history data based on the slider value
  const filteredHistory = props.history.filter((_, index) => {
    return filterLevel === 0 || index % filterLevel === 0;
  });

  const length = filteredHistory.length;
  let currentPos = {};
  let historyData = [];
  let historyData50 = [];
  let historyData100 = [];
  let historyData250 = [];

  if (length > 0) {
    currentPos = { x: filteredHistory[0].xPos, y: filteredHistory[0].zPos, r: 8 };
  }
  if (length > 1) {
    for (let i = 1; i < length && i < 51; i++) {
      if (filteredHistory[i].xPos === filteredHistory[i - 1].xPos && filteredHistory[i].yPos === filteredHistory[i - 1].yPos) { continue; }
      historyData.push({ x: filteredHistory[i].xPos, y: filteredHistory[i].zPos, r: 5 });
    }
  }
  if (length > 50) {
    for (let i = 51; i < length && i < 101; i++) {
      if (filteredHistory[i].xPos === filteredHistory[i - 1].xPos && filteredHistory[i].yPos === filteredHistory[i - 1].yPos) { continue; }
      historyData50.push({ x: filteredHistory[i].xPos, y: filteredHistory[i].zPos, r: 4 });
    }
  }
  if (length > 100) {
    for (let i = 101; i < length && i < 251; i++) {
      if (filteredHistory[i].xPos === filteredHistory[i - 1].xPos && filteredHistory[i].yPos === filteredHistory[i - 1].yPos) { continue; }
      historyData100.push({ x: filteredHistory[i].xPos, y: filteredHistory[i].zPos, r: 3 });
    }
  }
  if (length > 250) {
    for (let i = 251; i < length; i++) {
      if (filteredHistory[i].xPos === filteredHistory[i - 1].xPos && filteredHistory[i].yPos === filteredHistory[i - 1].yPos) { continue; }
      historyData250.push({ x: filteredHistory[i].xPos, y: filteredHistory[i].zPos, r: 2 });
    }
  }

  let data = {
    datasets: [
      {
        label: 'Most Recent',
        data: [currentPos],
        backgroundColor: 'rgba(0,191,255, 0.5)',
      },
      {
        label: '2-50 Most Recent',
        data: historyData,
        backgroundColor: 'rgba(0, 255, 133, 0.4)',
      },
      {
        label: '51-100 Most Recent',
        data: historyData50,
        backgroundColor: 'rgba(255, 165, 0, 0.2)',
      },
      {
        label: '101-250 Most Recent',
        data: historyData100,
        backgroundColor: 'rgba(255, 255, 55, 0.2)',
      },
      {
        label: '251+ Most Recent',
        data: historyData250,
        backgroundColor: 'rgba(255, 55, 55, 0.2)',
      },
    ],
  };

  return (
    <div className="Canvas">
      <Bubble options={options} data={data} />
      <div style={{ marginTop: '20px' }}>
        <label>
          Filter Level: {filterLevel}
          <br></br>
          <input
            type="range"
            min="0"
            max="200"
            value={filterLevel}
            onChange={(e) => setFilterLevel(parseInt(e.target.value))}
          />
        </label>
      </div>
    </div>
  );
}

export default BubbleChart;
