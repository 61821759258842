import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { backendURL } from '../../App';
import { Container, Typography, TextField, Button, List, ListItem, ListItemText, ListItemSecondaryAction, IconButton, Divider, Box, Checkbox, FormControlLabel, Switch, Paper, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Link } from 'react-router-dom';

function Tokens(props) {
  const [tokens, setTokens] = useState([]);
  const [newToken, setNewToken] = useState('');
  const [newAuthorized, setNewAuthorized] = useState(false);
  const [status, setStatus] = useState('init');
  const [open, setOpen] = useState(false);
  const [deleteToken, setDeleteToken] = useState('');

  useEffect(() => {
    listTokens();
  }, []);

  function listTokens() {
    const token = localStorage.getItem('token');
    if (!token) {
      console.error("Token is missing");
      setStatus('error');
      return;
    }

    axios.get(`${backendURL}/api/frontend/settings/tokens/list`, {
      headers: { Authorization: `Bearer ${token}` }
    })
      .then(res => {
        setTokens(res.data);
        setStatus('success');
      })
      .catch(err => {
        console.log(err);
        setStatus('error');
      });
  }

  function addToken(newToken, authorized) {
    const token = localStorage.getItem('token');
    if (!token) {
      console.error("Token is missing");
      setStatus('error');
      return;
    }

    axios.get(`${backendURL}/api/frontend/settings/tokens/add?newToken=${newToken}&authorized=${authorized}`, {
      headers: { Authorization: `Bearer ${token}` }
    })
      .then(res => {
        listTokens();
      })
      .catch(err => {
        console.log(err);
        setStatus('error');
      });
  }

  function handleClickOpen(token) {
    setDeleteToken(token);
    setOpen(true);
  }

  function handleClose() {
    setOpen(false);
    setDeleteToken('');
  }

  function handleDelete() {
    removeToken(deleteToken);
    handleClose();
  }

  function removeToken(removeToken) {
    const token = localStorage.getItem('token');
    if (!token) {
      console.error("Token is missing");
      setStatus('error');
      return;
    }

    axios.get(`${backendURL}/api/frontend/settings/tokens/remove?removeToken=${removeToken}`, {
      headers: { Authorization: `Bearer ${token}` }
    })
      .then(res => {
        listTokens();
      })
      .catch(err => {
        console.log(err);
        setStatus('error');
      });
  }

  function toggleToken(toggleToken) {
    const token = localStorage.getItem('token');
    if (!token) {
      console.error("Token is missing");
      setStatus('error');
      return;
    }

    axios.get(`${backendURL}/api/frontend/settings/tokens/toggle?toggleToken=${toggleToken}`, {
      headers: { Authorization: `Bearer ${token}` }
    })
      .then(res => {
        listTokens();
      })
      .catch(err => {
        console.log(err);
        setStatus('error');
      });
  }

  return (
    <Container maxWidth="md">
      <Box my={4}>
        <Typography variant="h4" component="h1" gutterBottom>
          Tokens
        </Typography>
        <Box display="flex" justifyContent="flex-start" my={2}>
          <Button
            variant="contained"
            color="primary"
            startIcon={<ArrowBackIcon />}
            component={Link}
            to="/Settings"
          >
            Back to Settings
          </Button>
        </Box>
        {status === 'init' && <Typography variant="h6">Loading...</Typography>}
        {status === 'error' && <Typography variant="h6" color="error">Error loading tokens.</Typography>}
        {status === 'success' && (
          <Box>
            <Paper elevation={3} sx={{ p: 2 }}>
              <List>
                {tokens.map((token, index) => (
                  <React.Fragment key={index}>
                    <ListItem>
                      <ListItemText 
                        primary={token.token} 
                        secondary={`Authorized: ${token.authorized}`} 
                      />
                      <ListItemSecondaryAction>
                        <Switch
                          checked={token.authorized}
                          onChange={() => toggleToken(token.token)}
                          color="primary"
                        />
                        <IconButton edge="end" aria-label="delete" onClick={() => handleClickOpen(token.token)}>
                          <DeleteIcon />
                        </IconButton>
                      </ListItemSecondaryAction>
                    </ListItem>
                    {index < tokens.length - 1 && <Divider />}
                  </React.Fragment>
                ))}
              </List>
            </Paper>
            <Box my={4}>
              <Typography variant="h6">Add Token</Typography>
              <TextField label="New Token" value={newToken} onChange={e => setNewToken(e.target.value)} fullWidth margin="normal" />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={newAuthorized}
                    onChange={e => setNewAuthorized(e.target.checked)}
                    color="primary"
                  />
                }
                label="Authorized"
              />
              <Button variant="contained" color="primary" onClick={() => addToken(newToken, newAuthorized)}>
                Add
              </Button>
            </Box>
          </Box>
        )}
      </Box>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Confirm Delete"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to delete this token? This action cannot be undone.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleDelete} color="secondary" autoFocus>
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
}

export default Tokens;
