import React, { useState, useEffect } from 'react';
import ThemeProvider from '@mui/material/styles/ThemeProvider';
import createTheme from '@mui/material/styles/createTheme';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import { getPlayerList } from './apiFunctions';
import { backendURL } from '../../App';

const navTheme = createTheme({
    palette: {
        primary: {
            main: '#c7c8c9'
        },
        text: {
            secondary: '#979899'
        }
    }
});

function PlayerList(props) {
    const [value, setValue] = useState('recents');
    const [players, setPlayers] = useState([]);

    useEffect(() => { 
        getPlayerList(setPlayers); 
    }, []);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    if (players.length === 0) { return <h1>...</h1>; }
    if (players === null) { return <h1>ERROR</h1>; }

    const listItems = players.map((player) =>
        <BottomNavigationAction
            onClick={() => props.handleSelection(player)}
            label={player}
            value={player}
            icon={<Avatar alt={player} src={backendURL + "/public/" + player + ".png"} />}
            key={player}
            sx={{ flex: '1 0 21%', maxWidth: '120px', minWidth: '80px' }} // Adjust width for wrapping
        />
    );

    return (
        <ThemeProvider theme={navTheme}>
            <Box sx={{ my: 4 }}>
                <Box sx={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center' }}>
                    {listItems}
                </Box>
            </Box>
        </ThemeProvider>
    );
}

export default PlayerList;
