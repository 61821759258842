import axios from "axios";
import { backendURL } from "../../App";

// Fetch player list
export function getPlayerList(setPlayers) {
  const token = localStorage.getItem('token');
  if (!token) {
    console.error("Token is missing");
    setPlayers(null);
    return;
  }

  axios.get(`${backendURL}/api/frontend/players`, {
    headers: { Authorization: `Bearer ${token}` }
  })
    .then(res => {
      setPlayers(res.data.players);
    })
    .catch(err => {
      console.log(err);
      setPlayers(null);
    });
}

// Fetch player access level
export function getPlayerAccess(setLevel, player) {
  const token = localStorage.getItem('token');
  if (!token) {
    console.error("Token is missing");
    setLevel(null);
    return;
  }

  axios.get(`${backendURL}/api/frontend/access/get?playerName=${player}`, {
    headers: { Authorization: `Bearer ${token}` }
  })
    .then(res => {
      console.log('Fetched level:', res.data);
      setLevel(res.data);
    })
    .catch(err => {
      console.log(err);
      setLevel(null);
    });
}

// Set player access level
export function setPlayerAccess(player, level) {
  const token = localStorage.getItem('token');
  if (!token) {
    console.error("Token is missing");
    return false;
  }

  return axios.get(`${backendURL}/api/frontend/Access/set?playerName=${player}&accessLevel=${level}`, {
    headers: { Authorization: `Bearer ${token}` }
  })
    .then(res => {
      return true;
    })
    .catch(err => {
      console.log(err);
      return false;
    });
}

// Fetch billable amount for a player
export function billablesGet(username, setBillable) {
  const token = localStorage.getItem('token');
  if (!token) {
    console.error("Token is missing");
    setBillable(null);
    return;
  }

  axios.get(`${backendURL}/api/frontend/billables/get?username=${username}`, {
    headers: { Authorization: `Bearer ${token}` }
  })
    .then(res => {
      setBillable(res.data.value);
    })
    .catch(err => {
      console.log(err);
      setBillable(null);
    });
}

// Reset billable for players
export function billablesReset(usernames, setStatus) {
  const token = localStorage.getItem('token');
  if (!token) {
    console.error("Token is missing");
    setStatus(null);
    return;
  }

  axios.get(`${backendURL}/api/frontend/billables/reset?usernames=${usernames.join(',')}`, {
    headers: { Authorization: `Bearer ${token}` }
  })
    .then(res => {
      setStatus(res.data);
    })
    .catch(err => {
      console.log(err);
      setStatus(null);
    });
}

// Fetch all user statuses
export async function getUserStatuses() {
  const token = localStorage.getItem('token');
  if (!token) {
    console.error("Token is missing");
    throw new Error('Token is missing');
  }

  try {
    const response = await axios.get(`${backendURL}/api/frontend/userStatuses/get`, {
      headers: { Authorization: `Bearer ${token}` }
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching user statuses:', error);
    throw error;
  }
}

// Update a user's status
export async function updateUserStatus(username, status) {
  const token = localStorage.getItem('token');
  if (!token) {
    console.error("Token is missing");
    throw new Error('Token is missing');
  }

  try {
    const response = await fetch(`${backendURL}/api/frontend/userStatuses/update`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      },
      body: JSON.stringify({ username, status }),
    });
    if (!response.ok) {
      throw new Error('Failed to update user status');
    }
  } catch (error) {
    console.error('Error updating user status:', error);
    throw error;
  }
}

// Check if a user exists by username
// Check if a user exists by username
export async function checkUserExists(username) {
  const token = localStorage.getItem('token');
  if (!token) {
    console.error("Token is missing");
    throw new Error('Token is missing');
  }

  try {
    const response = await axios.post(`${backendURL}/api/frontend/userStatuses/checkUserExists`, {
      username
    }, {
      headers: { Authorization: `Bearer ${token}` }
    });

    console.log('checkUserExists API Response:', response.data); // Log full response from API

    // Ensure we correctly return exists (if response format is different)
    if (response.data && typeof response.data.exists !== 'undefined') {
      return response.data;  // Return the entire response, so we can check `exists`
    }

    // Handle any unexpected response format
    throw new Error('Unexpected response format');
  } catch (error) {
    console.error('Error checking if user exists:', error);
    throw error;
  }
}


// Generate a signup token for a new user
export async function generateSignupToken(username, token) {
  const authToken = localStorage.getItem('token');
  if (!authToken) {
    console.error("Token is missing");
    throw new Error('Token is missing');
  }

  try {
    const response = await axios.post(`${backendURL}/public/generateSignupToken?token=Lilac`, {
      username
    }, {
      headers: { Authorization: `Bearer ${authToken}` }
    });
    return response.data.signupLink;  // Return the generated signup link
  } catch (error) {
    console.error('Error generating signup token:', error);
    throw error;
  }
}
