import React, { useState } from 'react';
import SendContract from './lawyer/SendContract';
import ContractList from './lawyer/ContractList';

const ContractManagement = () => {
  const [refresh, setRefresh] = useState(false);
  const token = localStorage.getItem('token');

  const refreshContracts = () => {
    setRefresh(!refresh);
  };

  return (
    <>
      <SendContract token={token} refreshContracts={refreshContracts} />
      <ContractList token={token} refresh={refresh} />
    </>
  );
};

export default ContractManagement;
