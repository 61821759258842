import React from 'react';
import { Box, TextField, Typography, Table, TableBody, TableCell, TableHead, TableRow, Paper } from '@mui/material';

function InstancesWithinRadius({
  targetCoordinates,
  radius,
  handleTargetCoordinateChange,
  handleRadiusChange,
  withinRadius
}) {
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
      <Box sx={{ display: 'flex', gap: 2, marginTop: 2 }}>
        <TextField
          label="Target X Coordinate"
          variant="outlined"
          name="x"
          value={targetCoordinates.x}
          onChange={handleTargetCoordinateChange}
          type="number"
        />
        <TextField
          label="Target Y Coordinate"
          variant="outlined"
          name="y"
          value={targetCoordinates.y}
          onChange={handleTargetCoordinateChange}
          type="number"
        />
        <TextField
          label="Target Z Coordinate"
          variant="outlined"
          name="z"
          value={targetCoordinates.z}
          onChange={handleTargetCoordinateChange}
          type="number"
        />
        <TextField
          label="Radius"
          variant="outlined"
          name="radius"
          value={radius}
          onChange={handleRadiusChange}
          type="number"
        />
      </Box>
      <Paper sx={{ marginTop: 4 }}>
        <Typography variant="h6" component="div" sx={{ padding: 2 }}>
          Instances Within Radius
        </Typography>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Time</TableCell>
              <TableCell>X Position</TableCell>
              <TableCell>Y Position</TableCell>
              <TableCell>Z Position</TableCell>
              <TableCell>Distance</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {withinRadius.map((log, index) => {
              const dx = log.xPos - targetCoordinates.x;
              const dy = log.zPos - targetCoordinates.z;
              const distance = Math.sqrt(dx ** 2 + dy ** 2);
              return (
                <TableRow key={index}>
                  <TableCell>{new Intl.DateTimeFormat('en-US', { dateStyle: 'short', timeStyle: 'long', timeZone: 'America/Phoenix' }).format(log.timestamp)}</TableCell>
                  <TableCell>{log.xPos}</TableCell>
                  <TableCell>{log.yPos}</TableCell>
                  <TableCell>{log.zPos}</TableCell>
                  <TableCell>{distance.toFixed(2)}</TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </Paper>
    </Box>
  );
}

export default InstancesWithinRadius;
