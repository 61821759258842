import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { Avatar, Box, Container, Grid, Typography, useTheme, AppBar, Toolbar } from '@mui/material';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
import EditIcon from '@mui/icons-material/Edit';
import { backendURL } from '../App';

const PublicContractView = () => {
  const { uuid } = useParams();
  const [contractDetails, setContractDetails] = useState(null);
  const theme = useTheme();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const apiUrl = `${backendURL}/public/contract/details/uuid/${uuid}`;
        console.log(`Fetching contract details from: ${apiUrl}`);
        const response = await axios.get(apiUrl);
        console.log('Response data:', response.data);
        setContractDetails(response.data);
      } catch (error) {
        console.error('Error fetching contract details:', error);
      }
    };

    if (uuid) {
      fetchData();
    } else {
      console.error("Invalid UUID: UUID is undefined or null");
    }
  }, [uuid]);

  if (!contractDetails) {
    return <Typography>Loading...</Typography>;
  }

  const formatDate = (timestamp) => {
    return timestamp ? new Date(timestamp).toLocaleString() : 'N/A';
  };

  const isSelfContract = contractDetails.senderUsername === contractDetails.username;

  return (
    <Container
      maxWidth="md"
      sx={{
        backgroundColor: theme.palette.background.paper,
        padding: '20px',
        borderRadius: '8px',
        boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)',
      }}
    >
      <AppBar position="static" color="default" sx={{ marginBottom: '20px' }}>
        <Toolbar>
          <Box sx={{ flexGrow: 1 }}>
            <Typography variant="h5" component="div">
              {isSelfContract ? 'Self Contract:' : 'Contract:'}
            </Typography>
            <Typography variant="subtitle1" component="div">
              {contractDetails.contractDetails || 'No details provided'}
            </Typography>
          </Box>
        </Toolbar>
      </AppBar>
      {isSelfContract ? (
        <Box display="flex" alignItems="center" sx={{ marginBottom: '20px' }}>
          <Avatar alt={contractDetails.username} src={`${backendURL}/public/${contractDetails.username}.png`} sx={{ marginRight: '10px' }} />
          <Box>
            <Typography variant="h6">User:</Typography>
            <Typography>@{contractDetails.username}</Typography>
          </Box>
        </Box>
      ) : (
        <Grid container spacing={2} alignItems="center" sx={{ marginBottom: '20px' }}>
          <Grid item xs={6}>
            <Box display="flex" alignItems="center">
              <Avatar alt={contractDetails.senderUsername} src={`${backendURL}/public/${contractDetails.senderUsername}.png`} sx={{ marginRight: '10px' }} />
              <Box>
                <Typography variant="h6">Sender:</Typography>
                <Typography>@{contractDetails.senderUsername}</Typography>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={6}>
            <Box display="flex" alignItems="center">
              <Avatar alt={contractDetails.username} src={`${backendURL}/public/${contractDetails.username}.png`} sx={{ marginRight: '10px' }} />
              <Box>
                <Typography variant="h6">Recipient:</Typography>
                <Typography>@{contractDetails.username}</Typography>
              </Box>
            </Box>
          </Grid>
        </Grid>
      )}
      <Grid container spacing={3} sx={{ marginBottom: '20px' }}>
        <Grid item xs={4} textAlign="center">
          <CalendarTodayIcon sx={{ fontSize: '24px', marginBottom: '10px' }} />
          <Typography variant="h6">Date Sent:</Typography>
          <Typography>{formatDate(contractDetails.sentTimestamp)}</Typography>
        </Grid>
        <Grid item xs={4} textAlign="center">
          <AssignmentTurnedInIcon sx={{ fontSize: '24px', marginBottom: '10px' }} />
          <Typography variant="h6">Signed:</Typography>
          <Typography>{contractDetails.signed === 'true' ? 'Yes' : contractDetails.signed === 'rejected' ? 'Rejected' : 'No'}</Typography>
        </Grid>
        <Grid item xs={4} textAlign="center">
          <EditIcon sx={{ fontSize: '24px', marginBottom: '10px' }} />
          <Typography variant="h6">Date Signed:</Typography>
          <Typography>{formatDate(contractDetails.signedTimestamp)}</Typography>
        </Grid>
      </Grid>
      <Box>
        <Typography variant="h5" textAlign="center" sx={{ marginBottom: '20px' }}>Contract</Typography>
        <iframe
          src={`${backendURL}/public/viewContract/${uuid}`}
          width="100%"
          height="600px"
          title="Contract"
          style={{ border: 'none' }}
        />
      </Box>
    </Container>
  );
};

export default PublicContractView;
