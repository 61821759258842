import React, { useState, useEffect, useRef } from 'react';
import ThemeProvider from '@mui/material/styles/ThemeProvider';
import createTheme from '@mui/material/styles/createTheme';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import {
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
  Divider,
  Collapse,
  Avatar,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import axios from 'axios';
import { backendURL } from '../../App';

const navTheme = createTheme({
  palette: {
    primary: {
      main: '#c7c8c9'
    },
    text: {
      secondary: '#979899'
    }
  }
});

function Alerts(props) {
  const { isAdmin } = props; // Destructure isAdmin from props
  const [alerts, setAlerts] = useState([]);
  const [groupedAlerts, setGroupedAlerts] = useState({});
  const [expanded, setExpanded] = useState({});
  const [status, setStatus] = useState('init');
  const alertsRef = useRef(alerts);

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      fetchAlerts(token);
      const interval = setInterval(() => fetchAlerts(token), 5000);
      return () => clearInterval(interval);
    }
  }, []);

  const fetchAlerts = (token) => {
    axios.get(`${backendURL}/api/frontend/alerts/list`, {
      headers: { Authorization: `Bearer ${token}` }
    })
      .then(res => {
        const newAlerts = res.data;
        updateAlerts(newAlerts);
        setStatus('success');
      })
      .catch(err => {
        console.error(err);
        setStatus('error');
      });
  };

  const updateAlerts = (newAlerts) => {
    const newGroupedAlerts = groupAlertsByUsername(newAlerts);
    setAlerts(newAlerts);
    setGroupedAlerts(newGroupedAlerts);
  };

  const groupAlertsByUsername = (alerts) => {
    const grouped = alerts.reduce((acc, alert) => {
      const key = alert.username;
      if (!acc[key]) {
        acc[key] = {};
      }
      const areaKey = `${alert.username}-${alert.area}`;
      if (!acc[key][areaKey]) {
        acc[key][areaKey] = [];
      }
      acc[key][areaKey].push(alert);
      return acc;
    }, {});
    return grouped;
  };

  useEffect(() => {
    if (alerts.length > 0) {
      const initialExpandedState = {};
      Object.keys(groupedAlerts).forEach(username => {
        initialExpandedState[username] = expanded[username] || {};
        Object.keys(groupedAlerts[username]).forEach(areaKey => {
          if (initialExpandedState[username][areaKey] === undefined) {
            initialExpandedState[username][areaKey] = false;
          }
        });
      });
      setExpanded(initialExpandedState);
    }
  }, [groupedAlerts]);

  const toggleExpand = (username, areaKey) => {
    setExpanded(prevExpanded => ({
      ...prevExpanded,
      [username]: {
        ...prevExpanded[username],
        [areaKey]: !prevExpanded[username][areaKey]
      }
    }));
  };

  const dismissAlert = (alertId) => {
    const token = localStorage.getItem('token');
    axios.get(`${backendURL}/api/frontend/alerts/dismiss`, {
      headers: { Authorization: `Bearer ${token}` },
      params: { alertId }
    })
      .then(() => {
        fetchAlerts(token);
      })
      .catch(err => {
        console.error(err);
        setStatus('error');
      });
  };

  const dismissAllAlertsForUser = (username) => {
    const userAlerts = Object.values(groupedAlerts[username]).flatMap(alerts => alerts);
    userAlerts.forEach(alert => {
      dismissAlert(alert.alertId);
    });
  };

  const dismissAllAlertsForUserAndArea = (username, areaKey) => {
    const userAreaAlerts = groupedAlerts[username][areaKey];
    userAreaAlerts.forEach(alert => {
      dismissAlert(alert.alertId);
    });
  };

  if (status === 'init') {
    return <Typography variant="h6">Loading...</Typography>;
  }

  if (status === 'error') {
    return <Typography variant="h6" color="error">Error loading alerts.</Typography>;
  }

  const getBackgroundColor = (depth) => {
    switch (depth) {
      case 1: return '#1a1a1a'; // Darker gray
      case 2: return '#212121'; // Base gray
      case 3: return '#282828'; // Slightly lighter gray
      default: return '#212121'; // Base gray
    }
  };

  return (
    <ThemeProvider theme={navTheme}>
      <Box sx={{ my: 4 }}>
        <List>
          {Object.keys(groupedAlerts).map((username) => (
            <React.Fragment key={username}>
              <ListItem button onClick={() => toggleExpand(username, 'all')} sx={{ backgroundColor: getBackgroundColor(1) }}>
                <Avatar alt={username} src={`${backendURL}/public/${username}.png`} sx={{ mr: 2 }} />
                <ListItemText
                  primary={username}
                  secondary={
                    <React.Fragment>
                      <Typography variant="body2">
                        Restricted Locations: {Object.keys(groupedAlerts[username]).length}
                      </Typography>
                      <Typography variant="body2">
                        Alarms: {Object.values(groupedAlerts[username]).reduce((acc, alerts) => acc + alerts.length, 0)}
                      </Typography>
                    </React.Fragment>
                  }
                />
                <ListItemSecondaryAction>
                  {isAdmin && (
                    <IconButton edge="end" aria-label="dismiss-all" onClick={() => dismissAllAlertsForUser(username)}>
                      <DeleteIcon />
                    </IconButton>
                  )}
                  <IconButton edge="end" aria-label="expand">
                    {expanded[username]?.['all'] ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                  </IconButton>
                </ListItemSecondaryAction>
              </ListItem>
              <Collapse in={expanded[username]?.['all']} timeout="auto" unmountOnExit>
                <List disablePadding>
                  {Object.keys(groupedAlerts[username]).map((areaKey) => {
                    const userAreaAlerts = groupedAlerts[username][areaKey];
                    const alertCount = userAreaAlerts.length;

                    if (alertCount === 1) {
                      const alert = userAreaAlerts[0];
                      return (
                        <React.Fragment key={alert.alertId}>
                          <ListItem sx={{ backgroundColor: getBackgroundColor(2) }}>
                            <ListItemText
                              primary={`Restricted area ${alert.area}`}
                              secondary={`Position: (${alert.xPos}, ${alert.yPos}, ${alert.zPos}) in ${alert.dimension} at ${new Date(parseInt(alert.timestamp)).toLocaleString()}`}
                            />
                            <ListItemSecondaryAction>
                              {isAdmin && (
                                <IconButton edge="end" aria-label="delete" onClick={() => dismissAlert(alert.alertId)}>
                                  <DeleteIcon />
                                </IconButton>
                              )}
                            </ListItemSecondaryAction>
                          </ListItem>
                          <Divider />
                        </React.Fragment>
                      );
                    }

                    return (
                      <React.Fragment key={areaKey}>
                        <ListItem button onClick={() => toggleExpand(username, areaKey)} sx={{ backgroundColor: getBackgroundColor(2) }}>
                          <ListItemText
                            primary={`Restricted area ${userAreaAlerts[0].area}`}
                            secondary={`Alerts: x${alertCount}`}
                          />
                          <ListItemSecondaryAction>
                            {isAdmin && (
                              <IconButton edge="end" aria-label="dismiss-all-area" onClick={() => dismissAllAlertsForUserAndArea(username, areaKey)}>
                                <DeleteIcon />
                              </IconButton>
                            )}
                            <IconButton edge="end" aria-label="expand">
                              {expanded[username]?.[areaKey] ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                            </IconButton>
                          </ListItemSecondaryAction>
                        </ListItem>
                        <Collapse in={expanded[username]?.[areaKey]} timeout="auto" unmountOnExit>
                          <List component="div" disablePadding>
                            {userAreaAlerts.map((alert) => (
                              <React.Fragment key={alert.alertId}>
                                <ListItem sx={{ pl: 4, backgroundColor: getBackgroundColor(3) }}>
                                  <ListItemText
                                    primary={`In ${alert.area}`}
                                    secondary={`Position: (${alert.xPos}, ${alert.yPos}, ${alert.zPos}) in ${alert.dimension} at ${new Date(parseInt(alert.timestamp)).toLocaleString()}`}
                                  />
                                  <ListItemSecondaryAction>
                                    {isAdmin && (
                                      <IconButton edge="end" aria-label="delete" onClick={() => dismissAlert(alert.alertId)}>
                                        <DeleteIcon />
                                      </IconButton>
                                    )}
                                  </ListItemSecondaryAction>
                                </ListItem>
                                <Divider />
                              </React.Fragment>
                            ))}
                          </List>
                        </Collapse>
                      </React.Fragment>
                    );
                  })}
                </List>
              </Collapse>
            </React.Fragment>
          ))}
        </List>
      </Box>
    </ThemeProvider>
  );
}

export default Alerts;
