import React, { useState, useEffect } from 'react';
import PlayerList from './players/PlayerList';
import { getPlayerAccess, setPlayerAccess, billablesGet, billablesReset, getUserStatuses, updateUserStatus, checkUserExists, generateSignupToken } from './players/apiFunctions';
import { Button, Container, Grid, Box, Typography, Paper, TextField, Dialog, DialogTitle, DialogContent, DialogActions, Snackbar, Alert } from '@mui/material';
import AccessConfig from './players/AccessConfig';

function Players(props) {
  const { isAdmin } = props;
  const [selected, setSelected] = useState('');
  const [level, setLevel] = useState('');
  const [billable, setBillable] = useState(null);
  const [status, setStatus] = useState('');
  const [userStatuses, setUserStatuses] = useState({});
  const [billableResetStatus, setBillableResetStatus] = useState('');
  const [userExists, setUserExists] = useState(null);  // Track if the user exists
  const [openDialog, setOpenDialog] = useState(false);  // For displaying the signup token
  const [dialogMessage, setDialogMessage] = useState('');  // Message inside the dialog
  const [signupLink, setSignupLink] = useState('');  // Store the signup link

  // Snackbar state for displaying success or error messages
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success'); // success, error, warning, info

  // Function to check if the user exists
  const checkUserExistence = async (player) => {
    try {
      const response = await checkUserExists(player);
      setUserExists(response.exists);  // Set based on the response's "exists" field
    } catch (error) {
      console.error('Error checking user existence:', error);
    }
  };

  // Function to generate signup token if the user doesn't exist
  const handleGenerateToken = async () => {
    try {
      const signupLink = await generateSignupToken(selected, props.token);
      setDialogMessage(`Signup link generated for ${selected}: ${signupLink}`);
      setSignupLink(signupLink);  // Store the signup link
      setOpenDialog(true);
    } catch (error) {
      setDialogMessage('Failed to generate signup token.');
      setOpenDialog(true);
    }
  };

  // Function to copy the signup link to clipboard
  const handleCopyLink = () => {
    navigator.clipboard.writeText(signupLink).then(() => {
      setSnackbarMessage('Signup link copied to clipboard!');
      setSnackbarSeverity('success');
      setOpenSnackbar(true);  // Open the Snackbar to show the success message
    }).catch(err => {
      setSnackbarMessage('Failed to copy signup link');
      setSnackbarSeverity('error');
      setOpenSnackbar(true);  // Open the Snackbar to show the error message
    });
  };

  // Function to handle closing the dialog
  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  // Function to handle closing the Snackbar
  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  const fetchUserStatuses = async () => {
    try {
      const statuses = await getUserStatuses();
      const statusMap = statuses.reduce((acc, user) => {
        acc[user.username] = user.status;
        return acc;
      }, {});
      setUserStatuses(statusMap);
    } catch (error) {
      console.error('Error fetching user statuses:', error);
    }
  };

  async function handleSettings(newLevel) {
    const binaryLevel = newLevel.toString(2).padStart(4, '0');
    const restrictedLevel = isAdmin ? binaryLevel : Math.min(parseInt(binaryLevel, 2), 14).toString(2).padStart(4, '0');

    const success = await setPlayerAccess(selected, restrictedLevel);
    if (success) {
      getPlayerAccess(setLevel, selected);
    }
  }

  async function handleStatusUpdate() {
    if (isAdmin) {
      try {
        await updateUserStatus(selected, status);
        fetchUserStatuses();
      } catch (error) {
        console.error('Error updating user status:', error);
      }
    }
  }

  function handleReset() {
    if (selected) {
      billablesReset([selected], (response) => {
        setBillableResetStatus(response);
        // Refresh the billable amount after reset
        billablesGet(selected, setBillable);
      });
    }
  }

  useEffect(() => {
    if (selected) {
      getPlayerAccess(setLevel, selected);
      billablesGet(selected, setBillable);
      checkUserExistence(selected);  // Check if the user exists
      if (isAdmin) {
        fetchUserStatuses();
      }
    }
  }, [selected, isAdmin]);

  return (
    <Container maxWidth="lg">
      <Grid container spacing={4}>
        <Grid item xs={12} md={4}>
          <Paper elevation={3} sx={{ p: 2 }}>
            <Typography variant="h6" gutterBottom>Player List</Typography>
            <PlayerList handleSelection={setSelected} />
          </Paper>
        </Grid>
        <Grid item xs={12} md={4}>
          <Paper elevation={3} sx={{ p: 2 }}>
            <Typography variant="h6" gutterBottom>Access Configuration</Typography>
            {selected && (
              <Box>
                <Typography variant="h6">Selected Player: {selected}</Typography>
                <AccessConfig 
                  token={props.token} 
                  player={selected} 
                  level={level} 
                  handleSettings={handleSettings} 
                />
                {isAdmin && (
                  <Box mt={2}>
                    <TextField
                      label="Status"
                      fullWidth
                      value={status}
                      onChange={(e) => setStatus(e.target.value)}
                      sx={{ mb: 2 }}
                    />
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={handleStatusUpdate}
                    >
                      Update Status
                    </Button>
                  </Box>
                )}
                {isAdmin && userStatuses[selected] && (
                  <Typography variant="body1" sx={{ mt: 1 }}>
                    Current Status: {userStatuses[selected]}
                  </Typography>
                )}

                {/* Show "Generate Signup Token" button only if the user does not exist */}
                {userExists === false && (
                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={handleGenerateToken}
                    sx={{ mt: 2 }}
                  >
                    Generate Signup Token
                  </Button>
                )}
              </Box>
            )}
          </Paper>
        </Grid>
        <Grid item xs={12} md={4}>
          <Paper elevation={3} sx={{ p: 2 }}>
            <Typography variant="h6" gutterBottom>Billables</Typography>
            {selected && (
              <Box>
                <Typography variant="h6">Billable Amount for {selected}</Typography>
                {billable !== null ? (
                  <Typography variant="h6">{billable}</Typography>
                ) : (
                  <Typography variant="h6">0</Typography>
                )}
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleReset}
                  sx={{ mt: 2 }}
                >
                  Reset Billable
                </Button>
                {billableResetStatus && <Typography variant="body2" color="error" sx={{ mt: 1 }}>{billableResetStatus}</Typography>}
              </Box>
            )}
          </Paper>
        </Grid>
      </Grid>

      {/* Dialog to display the signup token or error message */}
      <Dialog open={openDialog} onClose={handleCloseDialog}>
        <DialogTitle>Signup Token</DialogTitle>
        <DialogContent>
          <Typography>{dialogMessage}</Typography>
        </DialogContent>
        <DialogActions>
          {/* Button to copy the signup link */}
          <Button onClick={handleCopyLink} color="primary">
            Copy Signup Link
          </Button>
          <Button onClick={handleCloseDialog} color="primary">Close</Button>
        </DialogActions>
      </Dialog>

      {/* Snackbar to display success/error messages */}
      <Snackbar
        open={openSnackbar}
        autoHideDuration={3000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <Alert onClose={handleCloseSnackbar} severity={snackbarSeverity} sx={{ width: '100%' }}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Container>
  );
}

export default Players;
