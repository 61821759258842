import React, { useState, useEffect } from 'react';
import ThemeProvider from '@mui/material/styles/ThemeProvider';
import createTheme from '@mui/material/styles/createTheme';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { getPlayerList } from './apiFunctions';
import { backendURL } from '../../App';

const navTheme = createTheme({
  palette: {
    primary: {
      main: '#c7c8c9'
    },
    text: {
      secondary: '#979899'
    }
  }
});

function PlayerList({ handleSelection }) {
  const [players, setPlayers] = useState([]);

  useEffect(() => { getPlayerList(setPlayers); }, []);

  if (players.length === 0) { return <Typography variant="h6">Loading...</Typography>; }
  if (players === null) { return <Typography variant="h6">ERROR</Typography>; }

  const listItems = players.map((player) =>
    <BottomNavigationAction
      onClick={() => handleSelection(player)}
      label={player}
      value={player}
      icon={<Avatar alt={player} src={`${backendURL}/public/${player}.png`} />}
      key={player}
      sx={{ flex: '1 0 21%', maxWidth: '120px', minWidth: '80px' }}
    />
  );

  return (
    <ThemeProvider theme={navTheme}>
      <Box sx={{ my: 4 }}>
        <Typography variant="h4" component="h1" gutterBottom>
          Players in Constellation:
        </Typography>
        <Box sx={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center' }}>
          {listItems}
        </Box>
      </Box>
    </ThemeProvider>
  );
}

export default PlayerList;
